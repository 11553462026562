@import 'bootstrap/functions';

@import 'base/module';

@import 'bootstrap/bootstrap';

@import 'helpers/module';

@import 'core/module';

@import 'components/module';

@import 'pages/module';

@import 'vendor/module';

.btn.btn-accent {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.btn-primary {
	background-color: transparent;

}

.btn.btn-blue {
	color: $black;
	&:hover {
		background-color: transparent;
		box-shadow: none !important;
		color: $black;
	}
}

.btn-blue {
	background-color: transparent;
}

.btn-orange {
	background-color: transparent;
}

.btn-orange:hover {
	background-color: transparent;
}

.btn.btn-orange:hover:not(.no-hover) {
    color: $white!important;
    font-weight:700;
}

.nav-tabs .nav-item .nav-link.active {
    background-color: $red;
}

.nav-tabs .nav-item .nav-link {
	color: $white;
	font-size: .9rem;
}
.nav-tabs .nav-item {
	margin-bottom: 10px;
}
.nav-tabs.desktop-tabs--team .nav-item .nav-link:before {
	background-color: $lightgreen;
}
.dropdown-toggle:hover span {
    color: inherit !important;
}
.team-figure-bios .card:hover .view-profile:before {
	background-color: $lightgreen;
}
.view-profile span:hover {
	color: $black !important;
}
body {
	color: $black;
}

.fa-envelope:before {
	color: $black;
}

.btn-red {
	background: $red;
	border-radius: 10px;
	color: $white;
}
.btn-text-white {
	color: $white !important;
}

.emergency-line-mobile {
	background-color: $lightgray;
}

.secondary-menu__back-btn {
	padding-left: 1rem !important;
}

.nav-item hr {
	background: $black !important;
}

.show hr {
	background: $green !important;
}

h3.font-weight-normal {
	font-weight: 700 !important;
}

p, ol li {
	font-family: $font-family-sans-serif;
	font-weight: 500 !important;
	line-height: 32.5px;
}
a, span {
	font-family: $font-family-sans-serif;
}

a {
	color: $red;
}

a.text-white:focus, a.text-white:hover {
    color: $green;
}

.btn-hover-red:hover {
	color: $red !important;
}
.btn-hover-green:hover {
	color: $green !important;
}
.btn-hover-blue:hover {
	color: $blue !important;
}
.btn-hover-white:hover {
	color: $white !important;
}
.btn-hover-black:hover {
	color: $black !important;
}

html, body {
	width: 100%;
	overflow-x: hidden;
}

#news-carousel h3 {
	color: $white;
}

[x-cloak] {
	display: none !important;
}