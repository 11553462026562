@media screen and (max-width: breakpoint-max(lg)) {
  #global-nav {
    // Fixes Safari and Chrome mobile issue. 'flex-wrap: wrap' added to #main-nav to prevent 0 shrink allowing nav items to appear off page
    flex: 1 0 auto;
    @include z-depth-3;
    color: white;
    font-family: $font-family-sans-serif;
    z-index: 10;

    // Navbar container constrains width on desktop, but set to max width on collapse
    .container {
      &__nav {
        position: relative;
        padding: 0;
        max-width: 100%;

        .navbar-brand {
          img {
            height: 50px;
          }
        }
      }
    }

    // General styling to nav-links and dropdown items
    .nav-item {
      .nav-link, .dropdown-item span {
        font-weight: 500;
        letter-spacing: 0.85px;
        text-transform: uppercase;
        cursor: pointer;
        transition: 0.2s;
      }

      .nav-link, .dropdown-item {
        &.active {
          span {
            padding-bottom: map_get($spacers, 1);
            border-bottom: 1px solid $orange;
          }
        }
      }
    }

    .dropdown-toggle {
      font-weight: $font-weight-semi-bold;

      &::after {
        transform: rotate(-90deg);
      }
    }

    #main-nav-mobile {
      .dropdown-toggle {
        // Colour of BS dropdown toggle 'arrow'
        &::after {
          border-top-color: $white !important;
        }
      }
    }

    #mobile-nav {
      background-color: $black;
      color: $white;
      top: 10px;

      .nav-menu--primary {
        .nav-link {
          color: $white;
        }

        .nav-item {
          padding: map_get($spacers, 2) 0;
        }
      }
    }

    // Primary and seondary nav menu's
    .nav-menu {
      padding-bottom: map_get($spacers, 4);

      // The initial menu/list items that display when nav is 'opened'
      &--primary {
        position: relative;
        top: 0;
        left: -100%;
        width: 100%;
        height: calc(100vh);
        padding: map_get($spacers, 3);
        overflow-y: auto;
        visibility: hidden;
        transition: 0.3s ease-in-out;
        z-index: 0;

        &.show {
          visibility: visible;
          left: 0;
          z-index: $zindex-dropdown;
        }
      }

      // Second level nav menu's, triggered by dropdown toggles in primary menu
      &--secondary {
        position: absolute;
        top: 0;
        left: 100%;
        width: 100%;
        height: calc(100vh - 65px);
        padding: map_get($spacers, 3);
        overflow-y: auto;
        visibility: hidden;
        transition: 0.3s ease-in-out;
        z-index: 0;

        &.show {
          visibility: visible;
          left: 0;
          z-index: $zindex-dropdown;
          transition: 0.3s ease-in-out;
        }

        .secondary-menu__back-btn {
          position: relative;
          min-width: auto;
          padding-left: map_get($spacers, 3);
          font-weight: $font-weight-semi-bold;

          svg {
            position: absolute;
            top: 3px;
            left: 0;
            height: 12px;
            width: auto;
            transform: rotate(180deg);
          }
        }

        // Heading/landing page link on submenu's
        .nav-item--landing {
          .nav-link {
            span {
              position: relative;
              padding-right: map_get($spacers, 3);

              // SVG arrow
              .nav-link__arrow {
                position: absolute;
                right: 0;
                top: 2px;
                height: 14px;
                width: auto;
                padding-left: map_get($spacers, 2);
              }
            }
          }
        }

        // Applies to headings such as 'Respond' and 'Mitigate'
        .secondary-menu__solution-group-heading {
          color: $green;
          // Border colour applied by BS 'border-' class
          border-bottom-width: 1px;
          border-bottom-style: solid;
        }

        // Applies to solutions within groups such as 'Respond' and 'Mitigate'
        .secondary-menu__solution-group {
          .secondary-menu__item {
            font-size: $font-size-base * 0.8125;
            text-decoration: none;

            &.active {
              span {
                line-height: 24px;
                padding-bottom: map_get($spacers, 1);
                border-bottom-width: 1px;
                border-bottom-style: solid;
              }
            }

            &:focus {
              font-size: 90%;
              color: color-mix(in srgb, #D4FF00 20%, white) !important;

              // filter: brightness(105%);
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: breakpoint-max(md)) {
  #global-nav {
    padding-bottom: 0px !important;
    padding-top:5px !important;
  }
}