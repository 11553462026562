main.governmentSolutionsLanding {
  .solutions-deck--tabs {

    // Apply 'active' and hover colours to tabs dependent on the solution groups related theme color.
    .nav-link {
      background-color: $gray-400;

      &.active, &:hover {
        &.nav-link--yellow {
          background-color: $yellow;
        }

        &.nav-link--pink {
          // Blue text is illegible on pink bg, let this be an edge case.
          color: $white;
          background-color: $pink;
        }

        // &.nav-link--green {
        //   background-color: $green;
        // }

        // &.nav-link--green:hover {
        //   background-color: $green;
        //   color: $black;
        // }

        &.nav-link--green:not(.active):hover {
          background-color: $green;
          color: $black;
        }

        &.nav-link--green:active {
          background-color: $red;
          color: $white;
        }

        

        &.nav-link--mitigate-blue {
          background-color: $mitigateblue;
        }
        &.nav-link--orange {
          color: $white;
          background-color: $orange;
        }
      }
    }
  }
}
img.lifecycle-graphic {
    margin: auto;
    display: block;
    width: 100%;
    max-width: 980px;
}