#global-nav {
  // Fixes Safari and Chrome mobile issue. 'flex-wrap: wrap' added to #main-nav to prevent 0 shrink allowing nav items to appear off page
  flex: 1 0 auto;

  @include z-depth-3;
  color: white;
  font-family: $font-family-sans-serif;
  font-size: $font-size-p-small;
  font-weight: 500;
  z-index: 10;
  background-color: $white;

  // Navbar container constrains width on desktop, but set to max width on collapse
  .container {
    &__nav {
      @media screen and (max-width: breakpoint-max(xl)) {
        max-width: 100%;
      }
    }
  }
}

.mega-menu-container {
  position: relative;
  z-index: $zindex-dropdown;
}

.nav-emergency-line {
  font-size: $font-size-p-small;
  letter-spacing: .5px;
}