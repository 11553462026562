.form-control {
  padding: map_get($spacers, 4);
  border-radius: 0;
  font-family: $font-family-sans-serif;
  font-size: $font-size-base * 0.875;
  color: $black;
  box-shadow: none !important;
  transition: 0.3s border;

  &:focus {
    color: $blue;
    border: 2px solid transparentize($authenticblue, 0.5);
  }

  &::placeholder {
    color: $blue;
    text-transform: capitalize;
  }

  &.form-control--ie {
    min-height: 48px;

    &:not(textarea) {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }

  // Error list that appears below form control
  &__error {
    list-style: none;
  }
}

.freeform-form {
  // Apply a loader to submit buttons when 'submitting' class is added
  button[type=submit] {
    position: relative;

    // Applied to submit button whilst AJAX is awaiting a response
    &.submitting {
      pointer-events: none;
      color: transparent !important;

      // Reuse BS spinner CSS and tweak some values
      &::before {
        content: "";
        position: absolute;
        top: calc(50% - 7px);
        left: calc(50% - 7px);
        display: inline-block;
        width: 14px;
        height: 14px;
        vertical-align: text-bottom;
        border: $spinner-border-width solid $white;
        border-right-color: transparent;
        border-radius: 50%;
        animation: spinner-border .75s linear infinite;
      }
    }
  }

  .fragment-link {
    display: block;
    position: relative;
    // Nav size on desktop
    top: $nav-offset-fragment-size-desktop;
    visibility: hidden;

    @media screen and (max-width: breakpoint-max(md)) {
      top: $nav-offset-fragment-size-mobile;
    }
  }

  // Custom select button & menu
  .custom-select-dropdown {
    padding: 0;

    > a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 48px;
      padding: 0 map_get($spacers, 3);

      &[aria-expanded="true"] {
        .dropdown-toggle-indicator {
          transform: rotate(90deg);
        }
      }

      // Arrow indicator SVG
      .dropdown-toggle-indicator {
        width: 15px;
        height: 20px;
        transition: 0.15s ease-in-out;

        * {
          fill: $orange;
          stroke: $orange;
          stroke-width: 0;
        }
      }
    }

    .dropdown-menu {
      max-height: 200px;
      overflow-y: auto;
      margin-right: map_get($spacers, -3);
      border-radius: 0;
      background-color: $white;

      .dropdown-item {
        &:hover, &:focus {
          background-color: $pale-blue-30;
        }

        &[data-checked=true] {
          position: relative;
          font-weight: $font-weight-semi-bold;

          &:before {
            content: '\2713';
            position: absolute;
            left: calc(#{map_get($spacers, 4)} / 2);
            transform: translateX(-50%);
          }
        }
      }
    }
  }

  .conditional-file-uploads {
    .conditional-upload {
      input[type=file] {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: auto;
        position: absolute;
        z-index: -1;
      }

      input[type=file] + label {
        display: inline-block;
        cursor: pointer;
      }
    }

    .conditional-upload-toggle {
      font-size: $font-size-base;
    }
  }

  .file-upload-message {
    font-size: $font-size-base * 0.875;
  }

  .form-success__tick {
    width: 70px;
    height: 70px;
  }
}
