.video-thumbnail {
  position: relative;
  min-height: 100px;
  background-color: $pale-blue-30;

  &__play {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 70px;
    height: auto;
    transform: translate(-50%, -50%);
  }
}