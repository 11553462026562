$generic-card-hover-bg-color: $primary;
$generic-card-hover-color: $white;

$generic-card-hover-bg-alt-color: $balancingblue;
$generic-card-hover-alt-color: $primary;
$generic-card-transition: 0.2s ease-in-out;

// Two class chain needed to add more specificity than BS card rules
.card.generic-card {
  background-color: $white;
  border-bottom: 2px solid $orange;
  transition: background-color 0.3s;

  &.card--hover {
    &:hover, &:focus {
      outline: none;

      .card-body {
        color: $generic-card-hover-alt-color !important;

        &::before {
          height: 100%;
          background-color: $lightgreen;
        }
        .card-title{
          color: $generic-card-hover-alt-color;
        }

        p {
          color: $generic-card-hover-alt-color;
          transition: $generic-card-transition;
        }
      }
    }
  }

  .card-header {
    height: 235px;
    background-color: $grey;
    border: 0;
    border-radius: 0;

    .witt-fallback {
      width: 75%;
      height: auto;
      transition: $transition-scale;
    }

    img.card-main-image {
      transition: $transition-scale;
    }
  }

  .card-body {
    // Custom spacer unit needed here for 'y' padding
    padding: calc(#{$spacer} * 2) map_get($spacers, 4);

    .card-title {
      font-size: $font-size-base * 1.125;
    }

    p {
      line-height: 20px;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0;
      background-color: transparentize($generic-card-hover-bg-alt-color, 0.5);
      z-index: -1;
      transition: 0.3s ease-in-out;
    }
  }

  &:hover {

    img.card-main-image {
      transform: scale(1.1);
      transition: $transition-scale;
    }

    .card-fallback-image {
      width: 85%;
      transition: $transition-scale;

      &.news-resource-card__fallback-logo--emblem {
        width: 40%;
      }
    }
  }
}
