h1, .h1 {
    line-height: 1.34;
    letter-spacing: -1px;
    font-weight: $headings-font-weight;
}

h2, .h2 { 
    line-height: 1.25;
    letter-spacing: -0.5px;
    font-weight: $font-weight-normal;
}

h3, .h3 { 
    line-height: 1.2;
    letter-spacing: -0.4px;
    font-weight: $font-weight-normal;
    scroll-margin-top: 150px;
}

h4, .h4 { 
    line-height: 1.25;
    letter-spacing: 0;
    font-weight: $font-weight-semi-bold;
}

h5, .h5 { 
    line-height: 1.3;
    letter-spacing: 0.2px;
    font-weight: $font-weight-semi-bold;
}

h6, .h6 { 
    line-height: 1.35;
    letter-spacing: 0.2px;
    font-weight: $headings-font-weight;
}