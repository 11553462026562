.generalContent {
    p {
      a {
        font-weight: $font-weight-bold;
        color: $red;
        text-decoration: underline;
  
        &:hover {
          text-decoration: none;
        }
      }
  
      strong {
        font-weight: $font-weight-bold;
      }
    }
  
    .sign-off-link {
      font-weight: $font-weight-semi-bold;
    }
  
    .right-slim-arrow {
      height: 15px;
      margin-top: -2px;
  
      * {
        stroke-width: 3px;
      }
    }

    blockquote {
      border-left: 1px solid $orange;
      width: 90%;
      font-size: $font-size-base * 1.375;
      font-family: $font-family-nunito-sans;
      font-weight: $font-weight-light;
      letter-spacing: -0.2px;
      padding-left: 18px;
      margin: 2rem 0;
    }
}