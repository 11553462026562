.link-strip {
  // If there is two links present, add a 'divider' between each item
  &--twin {
    div[class^=col]:first-child {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        background-color: $orange;

        @media screen and (min-width: breakpoint-min(md)) {
          top: 0;
          right: 0;
          width: 2px;
          height: 100%;
        }

        @media screen and (max-width: breakpoint-max(sm)) {
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
        }
      }
    }
  }

  .link-strip__arrow {
    width: 15px;
    height: 42px;
    transition: 0.2s ease-in-out;

    * {
      stroke: $blue;
      stroke-width: 6;
      transition: 0.2s ease-in-out;
    }
  }
}