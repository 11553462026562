.field__error {
  margin-top: map_get($spacers, 2);
  color: $respond;
  font-size: $font-size-base * 0.875;
  text-align: left;
}

// !important required to potentially override a parsley success (client side validation), this is applied by server
// side validation.
.field--has-errors {
  border: 2px solid $respond !important;
}

// Some forms require the use of parsley JS to validate their fields. In this event, we need useful styling on UI elements.
.parsley-success:not(label) {
  border: 2px solid $green;
}

.parsley-error:not(label) {
  border: 2px solid $respond;
}

.parsley-errors-list {
  list-style: none;
  margin-top: map_get($spacers, 1);
  margin-bottom: 0;
  padding-left: map_get($spacers, 2);

  li {
    color: $respond;
    font-size: $font-size-base * 0.875;
    text-align: left;
  }
}