#global-footer {
  color: $black;
  background-color: $white;
  font-size: $font-size-sm;

  .footer__content {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;

    .footer__contact {
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 1fr 1fr;
      grid-template-columns: repeat(2, 1fr);

      @media screen and (max-width: breakpoint-max(sm)) {
        font-size: $font-size-base * 0.75;
      }

      * {
        &:nth-child(1) {
          -ms-grid-column: 1;

          @media screen and (max-width: breakpoint-max(sm)) {
            -ms-grid-row: 1;
          }
        }

        &:nth-child(2) {
          -ms-grid-column: 2;

          @media screen and (max-width: breakpoint-max(sm)) {
            -ms-grid-column: 1;
            -ms-grid-row: 2;
          }
        }
      }

      @media screen and (max-width: breakpoint-max(sm)) {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
      }

      span, a {
        display: block;
      }

      a {
        text-decoration: none;
      }
    }

    .footer__strapline {
      .emergency-line-desktop {
        right: 0;
        top: -20px;
        font-size: $font-size-p-small;
        letter-spacing: .5px;
      }
    }

    .footer__tagline {
      @media screen and (max-width: breakpoint-max(sm)) {
        font-size: $font-size-base * 1.125;
      }
    }

    @media screen and (max-width: (breakpoint-max(sm))) {
      flex-direction: column;
      align-items: center;
    }
  }

  .footer__nav-logo {
    max-height: 60px;

    @media screen and (max-width: breakpoint-max(sm)) {
      max-height: 100px;
    }
  }

  .footer__nav-links {
    font-size: $font-size-base * 0.75;

    a {
      position: relative;
      color: $black;
      padding: 0 map_get($spacers, 2);

      &:first-child {
        padding-left: 0;
      }

      // Prevent last child having pipe and padding
      &:last-child {
        padding-right: 0;
        border-right: 0;
      }

      &:hover {
        // 15% darken, as used by BS in $link-hover-color
        color: darken($white, 15%);
        text-decoration: none;
      }

      @media screen and (max-width: breakpoint-max(sm)) {
        &:last-child {
          padding: 0;
        }

        // Last item wraps to separate line as per designs. Remove pipe from cookie policy.
        &:not(:nth-child(-n+2)) {
          border-right: 0;
        }
      }
    }
  }
}