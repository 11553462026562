// Desktop nav tab styling
.nav-tabs {
  &#desktop-tabs {
    display: flex;
  }

  &.desktop-tabs--solutions {
    @media screen and (max-width: breakpoint-max(sm)) {
      display: none !important;
    }
  }

  &.desktop-tabs--team {
    background-color: $blue-25;

    @media screen and (max-width: breakpoint-max(md)) {
      display: none !important;
    }

    .nav-item {
      border-right: 1px solid $authenticblue;

      &:first-of-type {
        border-left: 1px solid $authenticblue;
      }

      .nav-link {
        padding: 1.5rem 1rem;
        position: relative;

        &::before {
          position: absolute;
          width: 0;
          background-color: $orange;
          height: 100%;
          content: '';
          left: 0;
          top: 0;
          transition: all .4s ease-in-out;
        }

        &:hover {
          &::before {
            content: '';
            width: 100%;
            transition: all .4s ease-in-out;
          }
        }

        span {
          position: relative;
          z-index: 2;
        }
      }
    }
  }

  .nav-item {
    min-width: 145px;
    margin-bottom:0px !important;
    color: $primary;

    @media screen and (max-width: 910px) {
      min-width: 110px;
      margin-right: 0.5rem !important;
    }

    .nav-link {
      position: relative;
      // Custom spacer increment required to match designs
      padding: calc(#{$spacer} * 0.675) map_get($spacers, 3);
      border: 0;
      border-radius: 0;
      color: $primary;
      font-family: $font-family-sans-serif;
      font-size: $font-size-base;
      font-weight: 600;
      transition: 0.2s;

      &:hover {
        span {
          color: $white;
          transition: all .4s ease-in-out;
        }
      }

      &.active {
        background-color: $blue;
        span {
          color: $white;
        }
      }
    }
  }

  @media screen and (max-width: breakpoint-max(sm)) {
    .nav-item {
      display: none;
    }
  }
}

// Mobile dropdown toggle styling
#mobile-tab-toggle {
  width: 100%;
  font-family: $font-family-sans-serif;
  font-weight: 600;
  font-size: $font-size-base * 1.25;

  &.mobile-tab-toggle--solutions {
    @media screen and (min-width: breakpoint-min(md)) {
      display: none;
    }
  }

  &.mobile-tab-toggle--team {
    .nav-link {
      padding: .844rem 1rem;
    }
  }

  &.show {
    .nav-link {
      i {
        transform: rotate(90deg);
      }
    }
  }

  .nav-link {
    color: $primary;
    background-color: #D1D2D1;
    font-weight: 600;

    i {
      transition: transform 0.3s;
    }
  }

  .dropdown-menu {
    background-color: #D1D2D1;
    width: 100%;
    border-radius: 0;
    border: 0;

    // Required to override '.nav' display rules.
    &:not(.show) {
      display: none;
    }

    .dropdown-item {
      color: $primary;
      border-radius: 0;
      font-size: $font-size-base;
      text-align: center;
      white-space: normal;

      &:hover {
        background-color: inherit;
      }

      &.active {
        background-color: initial;
        font-weight: 600;
      }
    }
  }
}
