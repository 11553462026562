main.policies, main.generalContent {
  .policy-title {
    margin: 4rem 0;

    @media screen and (max-width: breakpoint-max(sm)) {
      margin: 3rem map_get($spacers, 3) 2rem map_get($spacers, 3);
    }

    h1 {
      font-family: $font-family-sans-serif;
      font-weight: 300;

      @media screen and (max-width: breakpoint-max(xs)) {
        font-size: 3rem;
      }
    }

    .bullet {
      height: 13px;
      width: 40px;

      &::after {
        height: 13px;
        width: 13px;
      }
    }
  }

  ul, ol {
    li {
      margin-bottom: map_get($spacers, 3)
    }
  }

}

main.policies {
  background-color: $blue-25;
}

main.generalContent {
  &--textOnly, &--textAndImages {
    h1, h2, h3, h4, h5, h6 {
      margin-bottom: map_get($spacers, 3);
      font-weight: $font-weight-semi-bold;
      text-transform: uppercase;
    }
  }

  // Target lists nested within a list item
  li {
    > ul, > ol {
      margin-top: map_get($spacers, 2);
    }
  }

  .general-card-deck {
    &__heading {
      margin-bottom: $spacer * 3.5;
    }

    &__btn-wrapper {
      margin-top: $spacer * 3.5;
    }
  }
}