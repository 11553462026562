.custom-checkbox-label {
    display: block;
    font-family: $font-family-sans-serif;
    position: relative;
    padding-left: 42px;
    margin-top: 8px;
    cursor: pointer;
    user-select: none;
    color: $white;
    z-index: 10;
    float: left;
    
    @media screen and (max-width: breakpoint-max(sm)) {
        margin-top: 6px;
        z-index: 0;
        margin-bottom: 26px;
    }

    @media screen and (max-width: breakpoint-max(xs)) {
        margin-top: 2px;
        z-index: 0;
        margin-bottom: 16px;
    }

    /* Hide the browser's default checkbox */
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        /* When the checkbox is checked, add a blue background */
        &:checked~.checkmark {
            background-color: $white;
        }

        /* On mouse-over, add a grey background color */
        &:hover input~.checkmark {
            background-color: $balancingblue;
        }

        /* Show the checkmark when checked */
        &:checked~.checkmark:after {
            display: block;
        }
    }

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: -6px;
        left: 0;
        height: 32px;
        width: 32px;
        background-color: $white;
        border: 2px solid $gray-400;
        transition: border .3s;

        @media screen and (max-width: breakpoint-max(sm)) {
            top: -6px;
        }

        @media screen and (max-width: breakpoint-max(xs)) {
            top: 0;
        }

        /* Add custom hover styling */
        &:hover{
            border: 2px solid rgba(5,34,73,.7);
        }

        /* Create the checkmark/indicator (hidden when not checked) */
        &:after {
            content: "";
            position: absolute;
            display: none;
        }

        &:after {
            left: 11px;
            top: 3px;
            width: 9px;
            height: 21px;
            border: solid $authenticblue;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }
    }

    &.focus {
        span {
            border: 2px solid transparentize($authenticblue, 0.6);
            transition: border .3s;
        }
    }
}
