main {
  // Offset for mobile nav
  @media screen and (max-width: breakpoint-max(sm)) {
    margin-top: 60px;
  }

  @media screen and (min-width: breakpoint-min(md)) and (max-width: breakpoint-max(md)) {
    margin-top: 60px;
  }

  @media screen and (min-width: breakpoint-min(lg)) {
    margin-top: 100px;
  }
}

// Page styling
.content-block {
  padding-top: $content-block-spacing;
  padding-bottom: $content-block-spacing;
}

// Background image defaults
.bg-img {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.bg-img--contain {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

// Use this class when an elements height is too large to display 'background-image: cover' by itself without causing
// pixellation.
.bg-img--fixed {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.obj-fit-contain {
  object-fit: contain !important;
}

.obj-fit-cover {
  object-fit: cover !important;
}

.white-space-normal {
  white-space: normal !important;
}

.border-width-medium {
  border-width: $border-width-medium !important;
}

.border-box {
  background-clip: border-box !important;
}

.clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}
html {
  scroll-padding-top: 117px;
}