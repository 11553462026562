.contact-form {
  .alert {
    &.alert-danger {
     border: 2px solid $pink;
    }

    &.alert-success {
      border: 2px solid $green;
    }
  }

  @media screen and (max-width: breakpoint-max(sm)){
    .grecaptcha-badge {
      margin: 0 auto;
    }
  }

  .form-submit-btn {
    margin-top: -61px;

    @media screen and (max-width: breakpoint-max(sm)) {
        margin-top: 16px;
        flex-direction: column;
        margin-bottom: 8px;

        .btn {
            padding: .7rem 3rem;
        }
    }

    @media screen and (max-width: breakpoint-max(md)) {
      margin-top: 4px;
    }

    @media screen and (max-width: breakpoint-max(sm)) {
      margin-top: 0;
    }

    @media screen and (max-width: breakpoint-max(xs)) {
      margin-top: 4px;
    }

    .btn {
        padding: .8rem 3rem;
    }
  }

  &#general-contact-block {
    .form-control {
      min-height: 60px;
      padding: 1.25rem 1.5rem;
    }

    .form-group {
      margin-bottom: .8rem;
    }

    #form-input-message {
      border: 1px solid $white;
    }

  }

}

#generalContactForm {
  input, textarea {
    &.form-control {
      background: none;
      border: 0;
      border-bottom: 1px solid $white;
      color: $white;

      &:focus {
        border: 0;
        border-bottom: 1px solid $white;
      }

      &::placeholder {
        color: $white;
      }
    }
  }

  textarea {
    .form-control {
      border: 1px solid $white;
    }
  }
}