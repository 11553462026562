.intro {
  &-block {
    position: relative;
    overflow: hidden;  
  }

  &__icon {
    max-height: 180px;
    max-width: 100%
  }

  &__button {
    &-wrapper {
      .btn.btn-accent {
        transition: all 250ms ease-in-out;
        
        &:hover {
          font-weight: 300;
        }
      }
    }
  }
}

.intro-block {
  h3 {
    @media screen and (max-width: breakpoint-max(md)) {
      font-size: $font-size-base * 1.75;
    }
  }

  .border-left-grey {
    border-left: 1px solid $grey-20;

    @media screen and (max-width: breakpoint-max(sm)) {
      border-left-color: transparent;
      margin-top: 16px;
    }
  }

  .intro-text {
    > * {
      // Ensure the the last element doesn't have undesirable bottom margin. Often <p> tags.
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.bg-graphic{
  &::before,
  &::after {
    position: absolute;
    content: '';
    z-index: 5;
    background-size: cover;
    margin-top: auto;
    margin-bottom: auto;

    @media screen and (max-width: breakpoint-max(sm)) {
      display: none;
    }
  }

  &::before {
    background: url('../../assets/img/wob-radar-left.svg') left no-repeat;
    height: calc(100% - 30px);
    max-height: 400px;
    width: 290px;
    top: 15px;
    bottom: 15px;
    left: 0;

    @media screen and (max-width: 1465px) {
      left: -135px;
    }
  }

  &::after {
    background: url('../../assets/img/wob-radar-right.svg') right no-repeat;
    height: calc(100% - 60px);
    max-height: 370px;
    width: 300px;
    top: 0;
    bottom: 60px;
    right: 0;

    @media screen and (max-width: 1465px) {
      right: -125px;
    }
  }
}