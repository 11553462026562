main.homepage {
  // Home hero should be 100px taller than all other hero's. Height and border hack (for triangle accent) amended to suit
  .hero {
    .hero__bg-img-wrapper {
      @media screen and (min-width: breakpoint-max(md)) {
        height: 500px;
      }
    }

    .hero__content-wrapper {
      .hero__content {
        > h1 {
          font-size: $font-size-base * 3.125;

          @media screen and (max-width: breakpoint-max(md)) {
            font-size: $font-size-base * 2;
          }
        }

        &::after {
          @media screen and (min-width: breakpoint-max(md)) {
            border-top: 500px solid transparentize($primary, 0.1) !important;
            border-right: 300px solid transparent !important;
          }
        }
      }
    }

    /* .hero__button-wrapper {
      a.btn.btn-accent {
        font-weight: 500;

        &:hover {
          font-weight: bold;
        }
      }
    } */
  }

  .solutions-overview {
    &__solution {
      padding: 0 map_get($spacers, 4);

      @media screen and (min-width: breakpoint-min(lg)) {
        padding: 0 ($spacer * 5) !important;
      }

      @media screen and (max-width: breakpoint-max(sm)) {
        border-right: none !important;
        
        &:not(:first-of-type) {
          margin-top: map_get($spacers, 5);
        }
      }

      > .img-wrapper {
        width: 120px;
        height: 120px;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }

      .overview-text {
        * {
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
      
      &:last-child {
        border: none !important;
      }
    }
  }

  /* #ourGrowthStory, #ourTeam {
    background-color: $black;
    color: $white;

    .btn.btn-accent {
      color: $white;
    }
  } */

  .solutions-overview {
    background-color: $black;
  }

  .news-resource-card__heading {
    color: $black;
  }
}