// Brand Colors

// -- Primary Palette
$authenticblue:     #052249;
$constructiveblue:  #D4FF00;
$balancingblue:     #A4C8E1;
$reneworange:       #FE383A;
$white:             #FFFFFF;
$resilientgrey:     #D9D9D9;
$lightgray:         #D9D9D9;
$red:               #FE383A;
$black:             #000000;
$blue:              $authenticblue;
$lightblue:         $constructiveblue;
$lightgreen:        #D4FF00;
$paleblue:          $balancingblue;
$bolderLightBlue:   #059fdc;


// -- Auxiliary Palette
$orange:        $reneworange;
$yellow:        #FF9E1B;
$pink:          #FE383A;
$green:         #D4FF00;
// This one doesn't really follow convention, but we already have a lightblue which has more prominence.
$mitigateblue:  #4298B5;


// -- 'Theme' Palette
$primary:       $authenticblue;
$secondary:     $balancingblue;
$prepare:       $yellow;
$respond:       $pink;
$recover:       $green;
$mitigate:      $mitigateblue;


// Greys
$grey:          $resilientgrey;


// 'Tints'
$blue-25:       #C0C8D1;
$blue-30:       #C8CED6;
$pale-blue-30:  #E3EEF6;
$grey-20:       #D6D9DA;
$dark-grey-50:  #CFD1D3;


// -- Theme Colors
$theme-colors: ();
$theme-colors: map_merge((
        "primary":        $primary,
        "secondary":      $secondary,
        "blue":           $blue,
        "light-blue":     $lightblue,
        "black":          $black,
        "pale-blue":      $paleblue,
        "red":            $red,
        "light-gray":     $lightgray,
        "orange":         $orange,
        "grey":           $grey,
        "white":          $white,
        "yellow":         $yellow,
        "pink":           $pink,
        "green":          $green,
        // This one doesn't really follow convention, but we already have a lightblue which has more prominence
        "mitigate-blue":  $mitigate,
        // Again, this one doesn't follow convention because there's already a 'lightblue'.
        "balancing-blue": $balancingblue,
        "blue-25":        $blue-25,
        "blue-30":        $blue-30,
        "pale-blue-30":   $pale-blue-30,
        "grey-20":        $grey-20,
        "dark-grey":      $resilientgrey,
        "dark-grey-50":   $dark-grey-50
), $theme-colors);

$menu-colors: ();
$menu-colors: map_merge((
        "blue":           $blue,
        "orange":         $orange,
        "yellow":         $yellow,
        "pink":           $pink,
        "green":          $green,
        "black":          $black,
        "mitigate-blue":  $mitigate,
), $menu-colors);


// Spacing
$spacer: 1rem !default;
$spacers: ();
$spacers: map-merge((
        -1: -($spacer * .25),
        -2: -($spacer * .5),
        -3: -$spacer,
        -4: -($spacer * 1.5),
        -5: -($spacer * 3),
        -6: -($spacer * 6)
), $spacers);


// Letter spacing
$letter-spacing-base: 0.125rem;


// Content Block Spacing
$content-block-spacing: ($spacer * 3);


// Font families
$font-family-heading: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-sans-serif: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-nunito-sans: "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

// Heading 'bullet' accents
$headingAccentWidth: 1.5rem;
$headingAccentHeight: 0.5rem;


// Small <p> font size
$font-size-p-small: 0.875rem;


// Border Widths
$border-width-medium: 2px;


// Font Weights
$font-weight-light: 300;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;


// Fragment Link Nav Offsets
$nav-offset-fragment-size-desktop: calc(-113px + #{map_get($spacers, -3)});
$nav-offset-fragment-size-mobile: calc(-67px + #{map_get($spacers, -3)});


// Global nav variables
$nav-dropdown-menu-l1-bg-color: $black;
$nav-dropdown-menu-l1-bg-color-mobile: $black;
$nav-node-active-accent-color: $white;
$nav-node-hover-accent-color: $orange;
$nav-node-active-accent-color-alt: $white;
$nav-node-hover-accent-color-alt: $orange;


// Button Variables
$btn-accent-size: 12px;


// About us - team figure widths
$team-figure-mobile-width: 100%;
$team-figure-sm-width: 240px;
$team-figure-lg-width: 265px;


// Social Media Icons
// Based on unicode values by fontawesome. See font awesome docs here... https://fontawesome.com/
$social-icons: ();
$social-icons: map-merge((
        "facebook": "\f39e",
        "instagram": "\f16d",
        "twitter": "\f099",
        "linkedin": "\f0e1",
        "googlePlus": "\f0d5",
        "youtube": "\f167",
        "vimeo": "\f27d"
), $social-icons);
