/**
  CSS to only target desktop menu. @see _global-nav-mobile.scss for anything below lg breakpoint.
 */
@media screen and (min-width: breakpoint-min(lg)) {

  #desktop-nav {
    // General styling to nav-links and dropdown items
    #main-nav-desktop {
      // Only target top level nav links
      > .nav-item > .nav-link {
        letter-spacing: 0.5px;
        // font-size: $font-size-base;
        font-size: 13px;

        @media screen and (max-width: 1103px) {
          font-size: 12px;
        }

        @media screen and (max-width: 790px) {
          font-size: 7px;
        }

        padding-left: 5px !important;
        padding-right: 5px !important;
        text-transform: uppercase;

        // Exception required to make space for additional unplanned 'nav-link'
        @media screen and (min-width: breakpoint-min(lg)) and (max-width: breakpoint-max(lg)) {
          letter-spacing: 0;
        }
      }
    }

    .nav-item {
      position: relative;

      &.top-item {

        > .nav-link {
          // Exception required to make space for additional unplanned 'nav-link'
          @media screen and (min-width: breakpoint-min(md)) and (max-width: breakpoint-max(md)) {
            // padding-left: $spacer * 0.125;
            // padding-right: $spacer * 0.125;
          }

          span {
            &:after {
              content: " ";
              height: 1rem;
              position: absolute;
              right: map_get($spacers, -1);
              background-color: $gray-400;
              width: 1px;
            }
          }
        }

        &:last-of-type {
          > .nav-link {
            span {
              &:after {
                content: initial;
              }
            }
          }
        }

        &.show {
          > .nav-link {
            span {
              &:after {
                content: initial;
              }
            }
          }
        }

        &.dropdown {
          &.show {
            &:after {
              content: ' ';
              width: var(--menuWidth);
              height: 30px;
              background: black;
              position: absolute;
            }
          }
        }

        /* &.top-item-mega {
          &.show {
            &:after {
              content: ' ';
              width: var(--menuWidth);
              height: 30px;
              background: black;
              position: absolute;
            }
          }
        } */

        &.active {
          hr {
            background: $lightgreen;
            opacity: 1;
            transition: .2s;
          }
        }

      }

    

      // '.nav-item--alert' class can be added in the navigation plugin to apply alternate colour to nav link i.e. COVID-19
      &--alert {
        .nav-link {
          color: $pink !important;
          transition: 0.2s ease-in-out;
        }

        &.show {
          .nav-link {
            color: $white !important;
          }
        }
      }

      .nav-link, .dropdown-item span {
        position: relative;
        color: $black;
        font-weight: 500;
        font-size: $font-size-base * 0.75;
        // text-transform: uppercase;
        cursor: pointer;
      }

      hr {
        background: $lightgreen; 
        width: 0;
        transition: .2s;
      }

      .dropdown-item {
        span {
          &::before {
            width: 100%;
          }
        }
      }

      &.show {
        .nav-link {
          color: $white;
          transition: .2s;
        }
        &.dropdown.show {
          background-color: $black;
        }
      }

      &.nav--mega-menu {
        position: static;
        .mega-menu {
          .separator-right {
            border-right: 1px solid $white;
          }

          .main-solutions {
            font-size: $font-size-base * 1.125;
            text-decoration: none;

            span {
              position: relative;

              &:before {
                position: absolute;
                content: '';
                width: 0;
                height: 1px;
                bottom: -1px;
                left: 0;
                background-color: $constructiveblue;
                transition: $transition-base;
              }
            }

            &:hover {
              color: $lightblue !important;

              span {
                &:before {
                  width: 100%;
                  transition: $transition-base;
                }
              }
            }

            svg {
              max-height: 1rem;
            }

            &.active {
              
              span {
                &:before {
                  width: 100%;
                  transition: $transition-base;
                }
              }
            }
          }

          .sub-solution {
            font-size: $font-size-base;
            color: #d4ff00;
            font-weight: 700;
            width: 50%;
            text-transform: capitalize;
          }

          .solution {
            white-space: normal;
            padding-left: .3em;

            &:hover {
              background: $green;
              
              span {
                color: $black;
              }
            }

            span {
              border-bottom: 0;
            }

            &.corp {
              transition: $transition-base;

              span {
                padding: 3px 5px;
                position: relative;

                &:before {
                  content: '';
                  position: absolute;
                  background-color: $constructiveblue;
                  transition: $transition-base;
                  height: 100%;
                  top: 0;
                  left: 0;
                  width: 0;
                  z-index: -1;
                }
              }

              &.active {

                span {
                  color: $authenticblue;

                  &:before {
                    transition: $transition-base;
                    width: 100%;
                  }
                }
              }

              &:hover {

                span {
                  color: $authenticblue;

                  &:before {
                    transition: $transition-base;
                    width: 100%;
                  }
                }
              }
            }

            &.gov {
              transition: $transition-base;

              span {
                padding: 3px 5px;
                display: flex;
                position: relative;

                &:before {
                  position: absolute;
                  content: '';
                  z-index: -1;
                  left: 0;
                  top: 0;
                  height: 100%;
                  width: 0;
                  transition: $transition-base;
                }
              }
            }
          }

          .solution-column {
            border-left: 1px solid;

            @each $color, $value in $menu-colors {
              &.border-#{$color} {
                border-color: $value !important;

                span.border-#{$color} {
                
                  &:hover {
                    @if $value == $pink {
                      color: $white;
                    }
                     @else if $value == $orange {
                      color: $white;
                     }
                     @else  {
                      color: $authenticblue;
                    }

                    &:before {
                      background-color: $value;
                      transition: $transition-base;
                      width: 100%;
                    }
                  }
                }
  
                .active {
  
                  span.border-#{$color} {
                    @if $value == $pink {
                      color: $white;
                    } @else  {
                      color: $authenticblue;
                    }
                    background-color: $value;
                    transition: $transition-base;
                  }
                }
              }
            }

          }

          @media screen and (min-width: breakpoint-min(xl)) {
            .government-solutions {
              .solution-column {
                width: 20%;
                max-width: 20%;
                padding-left: 10px;
                padding-right: 10px;
              }
            }
          }

        }

        // li.top-item.nav-item.dropdown.dropdown-l1.py-2.nav--mega-menu.show:after

        
      }

      &.nav--column-menu {
        .column-menu {
          .columns-1 {
            width: 175px;
          }
          .columns-2 {
            width: 350px;
          }
          .columns-3 {
            width: 525px;
          }
          .column-menu-title {
            font-size: $font-size-base * 1.125;
            text-decoration: none;

            span {
              position: relative;

              &:before {
                position: absolute;
                content: '';
                width: 0;
                height: 1px;
                bottom: -1px;
                left: 0;
                background-color: $constructiveblue;
                transition: $transition-base;
              }
            }

            svg {
              max-height: 1rem;
            }

            &:hover {
              color: $lightblue !important;

              span {
                &:before {
                  width: 100%;
                  transition: $transition-base;
                }
              }
            }

            &.active {
              
              span {
                &:before {
                  width: 100%;
                  transition: $transition-base;
                }
              }
            }
          }
          .nav-link {
            padding: 0;
            // margin: .5rem;

            span {
              padding: 3px 5px;
              display: flex;
              transition: $transition-base;
              position: relative;

              &:before {
                content: '';
                position: absolute;
                background-color: $lightgreen;
                height: 100%;
                left: 0;
                top: 0;
                width: 0;
              }
            }

            &:hover, 
            &.active {
              span {
                transition: $transition-base;
                color: $black;

                &:before {
                  transition: $transition-base;
                  width: 100%;
                  z-index: -1;
                }
              }
            }
          }
          .separator-right {
            border-right: 1px solid $white;
          }
          .separator-left {
            border-left: 1px solid $green;
          }

          &-container {
            margin: 0 35px; 
            border-left: 1px solid $green;
          }
              
          top: 57px;

        }
      }

      &:hover {
        hr {
          transition: .2s; 
          width: calc(100% - 1rem);
        }
      }

      &.show {
        hr {
          transition: .2s; 
          width: calc(100% - 1rem);
        }
      }
    }

    .nav-link, .dropdown-item {

      span {
        font-family: 'Neo Sans Std';
      }

    }

    .dropdown-l1 {
      > .dropdown-menu-l1 {
        // min-width: 11.325rem;
        // min-width: max-content;
        min-width: 275px;
        // max-width: 275px;
        // width: 100%;
        margin: 0 auto;
        border-radius: 0;
        border: 0;
        background-color: $nav-dropdown-menu-l1-bg-color;
        box-shadow: 0 12px 15px 0 rgba(0,0,0,.24), 0 17px 50px 0 rgba(0,0,0,.19);
        .dropdown-item {
          white-space: wrap;
        }

        .dropdown-item, .dropdown-toggle {

          span {
            padding-bottom: map_get($spacers, 1);
            padding: 3px 0px;
            white-space: break-spaces;
            color: $white;
            position: relative;
            width: 100%;
            display: block;

            &:before {
              position: absolute;
              content: '';
              width: 0;
              left: 0;
              top: 0;
              height: 100%;
              background-color: $lightgreen;
              transition: $transition-base;
              z-index: -1;
            }
          }

          &:hover {
            span {
              color: $black;
              transition: $transition-base;

              &:before {
                width: 100%;
                transition: $transition-base;
              }
            }
          }

          &:not(:first-child) {
            margin-top: map_get($spacers, 3);
          }

          &.active {
            background-color: inherit;
            font-weight: bold;

            span {
              color: $black; 
              width: 100%;
              display: block;
              
              &:before {
                width: 100%;
                transition: $transition-base;
              }
            }
          }

          &:hover, &:active, &:focus {
            background-color: inherit !important;
          }
        }

        &.mega-menu {
          max-width: 100vw;
          width: 100%;
        }

        &.dropdown-menu-right {
          right: 0;
        }
      }
    }

    // General L3 dropdown menu group styling
    .menu-group__dropdown-item-wrapper {
      .dropdown-item {
        position: relative;

        &::before {
          content: "";
          position: absolute;
          height: 1px;
          bottom: 0;
          left: map_get($spacers, 3);
          transition: 0.4s;
          opacity: 0;
          background-color: $nav-node-active-accent-color-alt;
        }

        &:hover, &.active {
          &::before {
            opacity: 1;
          }
        }
      }
    }

    .dropdown-menu {
      border-radius: 0;
      border: 0;
      background-clip: border-box;

      bottom: unset;
      // top: 122px;
    }

    #main-nav-desktop {
      flex-wrap: wrap;

      // 2nd Level dropdown styling
      .dropdown-l2 {
        > .dropdown-menu-l2 {
          border-bottom: 1px solid $white;

          .dropdown-item, .dropdown-item span {
            color: $white;

            &.active {
              color: transparentize($white, 0.5);
            }
          }
        }
      }
    }
  }
}
.column-menu-container {
  text-transform: capitalize;
}

@media screen and (max-width: 1024px) {
  #desktop-nav {
    // General styling to nav-links and dropdown items
    #main-nav-desktop {
      // Only target top level nav links
      > .nav-item > .nav-link {
        // font-size: 10px;
      }
    }
  }
}