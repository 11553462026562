$accent-size: 30px;
$figure-max-height: 190px;
$figure-max-width: 237px;
$figure-max-size-device: 320px;

.team-figure-bios {
  .team-category-title {
    font-family: $font-family-sans-serif;
    color: $white;

  }

  .card {
    height: auto;
    cursor: pointer;
    background-color: $white !important;

    a {
      text-decoration: none;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
    }

    .img-wrapper {
      position: relative;
      width: 100%;
      height: $figure-max-height;
      max-width: $figure-max-width;
      max-height: $figure-max-height;
      background-color: $dark-grey-50;
      overflow: hidden;

      @media screen and (max-width: breakpoint-max(md)) {
        max-width: none;
        height: auto;
        max-height: $figure-max-size-device;
      }

      img {
        transition: $transition-scale;
      }
    }

    .card-body {
      .card-text {
        color: $blue;
        font-family: $font-family-sans-serif;
        text-align: left;

        &__heading {
          font-size: 1.125rem;
          font-weight: 600;
          margin-bottom: 0;
        }

        &__subheading {
          font-size: 0.75rem;
          margin-bottom: 12px;
          text-transform: uppercase;
          line-height: 14px;
        }
      }
    }

    .view-profile {
      text-align: left;
      padding: 12px 20px;
      text-transform: uppercase;
      text-decoration: none;
      margin-bottom: 0;
      align-items: flex-end;
      font-family: $font-family-heading;
      font-weight: $headings-font-weight;
      font-size: $font-size-base * .875;
      transition: $transition-base;
      position: relative;

      &::before {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 0;
        transition: $transition-base;
        content: '';
      }

      svg {
        width: 15px;
        height: 15px;
        margin-top: -3px;
        position: relative;
        z-index: 2;
        margin-left: 5px;

        * {
          stroke: $orange;
          fill: $orange;
          transition: $transition-base;
        }
      }

      span {
        position: relative;
        z-index: 2;
      }
    }

    &:hover {
      .img-wrapper {
        img {
          transform: scale(1.1);
          transition: $transition-scale;
        }
      }

      .view-profile {
        // color: $white;
        transition: $transition-base;

        span {
          color: $black !important;
        }

        &::before {
          position: absolute;
          bottom: 0;
          left: 0;
          height: 42px;
          width: 100%;
          background-color: $authenticblue;
          transition: $transition-base;
          content: '';
        }
      }
    }
  }

  .fade {
    transition: opacity .4s linear;
  }
}