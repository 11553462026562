.staffMembers {
    border-top: 22px solid $authenticblue;

    .animate {
        height: 0;
        transition: $transition-base;
        overflow: hidden;
    }

    .get-in-touch {
        .form-control {
            min-height: 60px;
            padding: 1.25rem 1.5rem;
        }
    }
}

.staff-details {
    .staff-member {
        padding-top: 45px;

        &_title {
            font-size: $font-size-base * 2;
            font-weight: $headings-font-weight;
            letter-spacing: -0.5px;
            margin-bottom: 10px;
            line-height: $line-height-base;
        }
        
        &_qualifications {
            letter-spacing: 0.88px;
            font-size: $font-size-sm;
            font-weight: $font-weight-medium;
            text-transform: uppercase;
        }

        &_jobrole {
            color: $reneworange;
            font-weight: $headings-font-weight;
            font-size: $font-size-base * 1.2;
            letter-spacing: $font-weight-medium;
            text-transform: uppercase;
            margin-bottom: 30px;
        }

        &_bio {
            line-height: 20px;
            width: 90%;

            @media screen and (max-width: breakpoint-max(md)) {
                width: 100%;
            }

            p:first-child {
                font-size: 1.125rem;
                font-weight: 700;
                line-height: 1.25;
            }

            blockquote {
                border-left: 1px solid $orange;
                width: 90%;
                font-size: $font-size-base * 1.375;
                font-family: $font-family-nunito-sans;
                font-weight: $font-weight-light;
                letter-spacing: -0.2px;
                padding-left: 18px;
                line-height: 1.25;
            }
        }

        &_quote {
            border-left: 1px solid $orange;
            width: 90%;
            font-size: $font-size-base * 1.375;
            font-family: $font-family-nunito-sans;
            font-weight: $font-weight-light;
            letter-spacing: -0.2px;
            padding-left: 18px;
        }

        hr {
            background-color: $black;
            width: 100%;
            margin-bottom: 40px !important; /* !important to override BS helper class */

            @media screen and (min-width: breakpoint-min(lg)) {
                margin-left: 0;
                width: 90%;
            }

            @media screen and (max-width: breakpoint-min(md)) {
                width: 100%;
                margin-top: 0 !important; /* !important to override BS helper class */
            }

            @media screen and (max-width: breakpoint-min(sm)) {
                width: 100%;
            }
        }

        .img-wrapper {
            height: 300px;
        }

        @media screen and (max-width: breakpoint-max(sm)) {
            padding-top: 0;
            max-height: 700px;
            overflow: hidden;
            position: relative;
            width: 100%;
            transition: $transition-base;
        }

        &.remove-layer {
            max-height: 2500px;
            transition: $transition-base;
        }

        .layer {
            position: absolute;
            left: 0;
            width: 100%;
            height: 50px;
            bottom: 0;
            background-image: linear-gradient(to bottom, rgba(255,255,255,.7), rgba(255,255,255,.9));
        }

        .read-more {
            position: absolute;
            bottom: 2px;
            text-transform: uppercase;
            left: 15px;
            font-family: $font-family-heading;
            font-weight: $font-weight-bold;
            text-decoration: none;
            font-size: $font-size-base * .875;
            letter-spacing: .88px;

            &.show {
                position: relative;
                margin-top: 30px;
                left: 0;

                .arrow-left {
                    display: block;
                    left: 0;
                    top: 0;
                }
    
                .arrow-right {
                    display: none;
                }

                span {
                    margin-left: 20px;
                }
            }

            svg {
                width: auto;
                height: 18px;
                position: absolute;

                * {
                    stroke: $orange;
                    fill: $orange;
                    transition: $transition-base;
                }
            }

            .arrow-left {
                display: none;
            }

            .arrow-right {
                display: block;
                left: 103px;
                top: 0;
            }
        }

        .btn {
            margin-bottom: 20px;

            @media only screen and (max-width: 991px) {
                margin-bottom: 44px;
            }
        }

    }

    .right-panel {
        .experties {
            a {
                display: block;
                margin: 0 6px 10px 0;
                background-color: transparent;
                color: $white;
                font-size: $font-size-base * .856;
                text-align: left;
                // padding: 7px 20px;
                text-decoration: none;
                position: relative;

                @media screen and (max-width: breakpoint-max(md)) {
                    padding: 10px 22px;
                }

                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 0;
                    height: 100%;
                    background-color: $constructiveblue;
                    transition: $transition-base;
                }

                &:hover {

                    color: $black;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        background-color: $constructiveblue;
                        transition: $transition-base;
                    }
                }

                span {
                    position: relative;
                    z-index: 2;
                }
            }
        }
        

        .resource-panel {
            background-color: $black;
            border-top: 10px solid $red;
            text-decoration: none;

            hr {
                background-color: $red;
            }

            h2 {
                color: $authenticblue;
                font-family: $font-family-sans-serif;
                font-size: $font-size-base * 1.25;
                font-weight: 600;
                letter-spacing: 0;
                text-transform: uppercase;;
            }

            .related-resources {
                .news-resource-card {
                    border-top: 0px;
                }
                .news-resource-card__heading {
                    font-size: $font-size-base;
                    text-transform: none !important;
                    color: $black;
                }
                .news-resource-card__sundries {
                    color: $black;
                    span {
                        color: $black;
                    }
                }
            }

            .contact-me {
                /* .social-media a {
                    background-color: $white;
                    border-radius: 50%;
                    display: inline-flex;
                    padding: 6px 4px;
                    margin-right: 8px;
                    width: 36px;
                    height: 36px;
                    text-align: center;
                    @include link-hover-anim;

                    &.text-white {
                        &:hover {
                            color: white !important;
                        }
                    }

                    &:hover {
                        background-color: $green;
                        @include link-hover-anim;
                    }
                } */
            }
        }

        .img-wrapper {
            height: 280px;

            @media screen and (max-width: breakpoint-max(md)){
                height: 216px;
            }

            @media screen and (max-width: breakpoint-max(lg)){
                height: 231px;
            }
        }
    }

    .news-resource-card__heading {
        font-weight: $headings-font-weight;
    }

    .center-icon {
        // width: 25px;
        margin: 0 auto;
    }

    .btn.btn-accent {
        box-shadow: none;
        font-size: 20px;
        line-height: 50px;
        font-weight: $font-weight-regular;

        &:hover {
            box-shadow: none;
        }
    }
}
