.pagination {
  font-family: $font-family-sans-serif;
  font-weight: 500;

  .page-item {
    &.active {
      .page-link {
        background-color: $primary;
        color: $red;
        border:none;
        // border: 1px solid $primary;
      }
    }

    &.disabled {
      opacity: 0.6;
    }

    &:not(.disabled):hover {
      .page-link {
        color: $red;
        // background-color: $primary;
      }
    }

    .page-link {
      padding: map_get($spacers, 1) map_get($spacers, 2);
      min-width: 34px;
      text-align: center;
      transition: 0.2s;

      color: $black;
      background-color: transparent !important;
      border: none !important;

      i {
        color: $red;
      }
    }

    &:first-child, &:last-child {
      .page-link {
        border: none;
        // border: 1px solid $grey-20;
        // border-radius: 0 !important;
      }
    }

    &:not(:last-child) {
      margin-right: map_get($spacers, 2);
    }
  }
}