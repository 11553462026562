.article__content, 
.generalContent {
    ul {
        list-style: none;
        padding: 0 0 0 19px;
        margin-bottom: 2rem;

        li::before {
            content: "\2022";
            color: $reneworange;
            font-weight: bold;
            position: absolute;
            left: 16px;
        }

        > li {

            > ul {
                padding-left: 28px;

                li { 
                    &:before {
                        content: "\25E6";
                        color: $reneworange;
                        width: 1em;
                        position: absolute;
                        left: 44px;
                    }
                }
            }
        }
    }

    ol {
        counter-reset: counts 0;
        list-style: none;
        padding: 0 0 0 32px;
        margin-bottom: 2rem;

        > li {
            counter-increment: counts 1;
            align-items: baseline;
            margin-bottom: 16px;

            &:before {
                content: counter(counts) ".";
                color: $reneworange;
                font-weight: normal;
                position: absolute;
                left: 16px;
                margin-top: 2px;
            }

            > ol {
                padding-left: 28px;
                
                li { 
                    &:before {
                        content: "\25E6";
                        color: $reneworange;
                        width: 1em;
                        font-weight: bold;
                        position: absolute;
                        left: 44px;
                    }
                }
            }
        }
    }
}