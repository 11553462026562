.modal {
  .modal-content {
    border: none;
    border-radius: 0;
  }

  .modal-body {
    position: relative;

    .modal__loading {
      position: absolute;
      top: 50%;
      left: 50%;
      transform-origin: center;
      transform: translate(-50%, -50%);
    }

    .animate {
      height: 0;
      transition: $transition-base;
      overflow: hidden;
    }
  }

  &.get-in-touch {
    .modal-dialog {
      max-width: 1100px;

      &.shrink-modal {
        max-width: 500px;
      }
    }

    .modal-header {
      border: none;

      .close {
        font-size: $font-size-base;
        margin: -50px;
        opacity: .9;

        @media screen and (max-width: breakpoint-max(sm)) {
          margin: -18px;
        }

         .icon {
            &__close {
                position: relative;
                width: 22px;
                height: 17px;
                opacity: 1;
        
                &::before, &::after {
                    content: '';
                    position: absolute;
                    top: 8px;
                    left: 0;
                    height: 2px;
                    width: 20px;
                    background-color: $authenticblue;
                }
        
                &::before {
                    transform: rotate(45deg);
                }
        
                &::after {
                    transform: rotate(-45deg);
                }
            }
        }
      }
    }

    .form-submit-btn {
      margin-top: -48px;

      @media screen and (max-width: breakpoint-max(sm)) {
          margin-top: 16px;
          flex-direction: column;
          margin-bottom: 8px;

          .btn {
              padding: .7rem 3rem;
          }
      }

      @media screen and (max-width: breakpoint-max(md)) {
        margin-top: 4px;
      }

      @media screen and (max-width: breakpoint-max(sm)) {
        margin-top: 0;
      }

      @media screen and (max-width: breakpoint-max(xs)) {
        margin-top: 4px;
      }

      .btn {
          padding: .8rem 3rem;
      }
    }

    .modal-title {
      font-size: $font-size-base * 1.5;
      font-weight: $font-weight-normal;
    }

    .modal-content {
      background-color: $balancingblue;
      padding: 40px;

      @media screen and (max-width: breakpoint-max(sm)) {
        padding: 0;
      }
    }
  }
}