.covid19Resources {
    .news-resource-card {
        
        &__heading {
            color: $black;
        }

        &__sundries {
            span {
                color: $black;
            }
        }

        &:hover {
            .news-resource-card__category {
                color: $black;
            }
        }
    }
}