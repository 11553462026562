figure[class^="redactor-image--"], 
figure {
  @media screen and (min-width: breakpoint-min(sm)) {
    &.redactor-image--left {
      float: left;
      // !important flags to override any inline styles imposed by redactor
      margin-right: map_get($spacers, 3) !important;
    }

    &.redactor-image--center {
      display: block;
      margin: 0 auto map_get($spacers, 3) auto;
    }

    &.redactor-image--right {
      float: right;
      // !important flags to override any inline styles imposed by redactor
      margin-left: map_get($spacers, 3) !important;
    }
  }

  &.redactor-image--left, &.redactor-image--center, &.redactor-image--right {
    @media screen and (max-width: breakpoint-max(xs)) {
      display: block;
      margin: 0 auto map_get($spacers, 3) auto !important;
      float: none;
    }
  }
  
  &.redactor-image--right {

    figcaption {
      text-align: right;
    }
  }

  img {
    max-width: 100%;
    height: auto !important;

    + figcaption {
      padding-bottom: 16px;
      margin-top: map_get($spacers, 2);
      border-bottom: 1px solid $grey-20;
      text-transform: uppercase;
      font-size: $font-size-base * 0.75;
      font-style: italic;
      padding-top: 4px;
    }
  }
}

.img__caption {
  font-size: $font-size-base * 0.75;
  border-bottom: 1px solid $grey-20;
  padding-bottom: 16px;
  font-style: italic;
  padding-top: 4px;
}
