.lightpick {
  position: absolute;
  z-index: 99999;
  padding: 0;
  border-radius: 4px;
  background-color: $gray-300;
  color: $primary;
  font-family: $font-family-sans-serif;
  line-height: 1.125em;
  text-transform: uppercase;
}

.lightpick__inner {
  width: 100%;
}

.lightpick--inlined {
  position: relative;
  display: inline-block;
}

.lightpick,
.lightpick *,
.lightpick::after,
.lightpick::before {
  box-sizing: border-box;
}

.lightpick.is-hidden {
  display: none;
}

.lightpick__months {
  display: grid;
  background-color: $gray-300;
  grid-template-columns: 1fr;
  margin: 1px;
}

.lightpick--2-columns .lightpick__months {
  grid-template-columns: 1fr 1fr;
}

.lightpick--3-columns .lightpick__months {
  grid-template-columns: 1fr 1fr 1fr;
}

.lightpick--4-columns .lightpick__months {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.lightpick--5-columns .lightpick__months {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.lightpick__month {
  padding: 0;
  width: 100%;
  background-color: $gray-300;
}

.lightpick__month-title-bar {
  display: flex;
  margin-bottom: 4px;
  justify-content: space-between;
  align-items: center;
}

.lightpick__month-title {
  margin-top: 4px;
  margin-bottom: 4px;
  margin-left: 4px;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  cursor: default;
  padding: 0 4px;
  border-radius: 4px;
}

.lightpick__month-title > .lightpick__select {
  border: none;
  background-color: transparent;
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  text-align-last: center;
  font-family: $font-family-sans-serif;
  color: $primary !important;
  text-transform: uppercase;
  font-weight: normal !important;
  &::-ms-expand {
    display:none;
  }
}
.lightpick__month-title > .lightpick__select:disabled {
  color: #333;
}

.lightpick__toolbar {
  display: flex;
  text-align: right;
  justify-content: flex-end;
}

.lightpick__previous-action,
.lightpick__next-action,
.lightpick__close-action {
  display: flex;
  width: 32px;
  height: 32px;
  outline: none;
  border: none;
  border-radius: 50%;
  background-color: $gray-300;
  justify-content: center;
  align-items: center;

  .fas {
    font-size: 1.25rem !important;
    color: $primary !important;
    pointer-events: none;
  }
}

.lightpick__previous-action,
.lightpick__next-action {
  font-size: 12px;
}

.lightpick__close-action {
  font-size: 18px;
}

.lightpick__previous-action:active,
.lightpick__next-action:active,
.lightpick__close-action:active {
  color: inherit;
}

.lightpick__days-of-the-week {
  display: grid;
  grid-template: "a b c d e f g" 1fr /
                  1fr 1fr 1fr 1fr 1fr 1fr 1fr;

  * {
    &:nth-child(1) {
      grid-area: a;
    }
    &:nth-child(2) {
      grid-area: b;
    }
    &:nth-child(3) {
      grid-area: c;
    }
    &:nth-child(4) {
      grid-area: d;
    }
    &:nth-child(5) {
      grid-area: e;
    }
    &:nth-child(6) {
      grid-area: f;
    }
    &:nth-child(7) {
      grid-area: g;
    }
  }
}

.lightpick__day-of-the-week {
  display: flex;
  font-size: 11px;
  font-weight: normal;
  justify-content: center;
  align-items: center;
}

.lightpick__days {
  display: grid;
  grid-template: "a  b  c  d  e  f  g " auto
                 "h  i  j  k  l  m  n " auto
                 "o  p  q  r  s  t  u " auto
                 "v  w  x  y  z  aa ab" auto
                 "ac ad ae af ag ah ai" auto
                 "aj ak al am an ao ap" auto /
                  1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  * {
    &:nth-child(1) {
      grid-area: a;
    }
    &:nth-child(2) {
      grid-area: b;
    }
    &:nth-child(3) {
      grid-area: c;
    }
    &:nth-child(4) {
      grid-area: d;
    }
    &:nth-child(5) {
      grid-area: e;
    }
    &:nth-child(6) {
      grid-area: f;
    }
    &:nth-child(7) {
      grid-area: g;
    }
    &:nth-child(8) {
      grid-area: h;
    }
    &:nth-child(9) {
      grid-area: i;
    }
    &:nth-child(10) {
      grid-area: j;
    }
    &:nth-child(11) {
      grid-area: k;
    }
    &:nth-child(12) {
      grid-area: l;
    }
    &:nth-child(13) {
      grid-area: m;
    }
    &:nth-child(14) {
      grid-area: n;
    }
    &:nth-child(15) {
      grid-area: o;
    }
    &:nth-child(16) {
      grid-area: p;
    }
    &:nth-child(17) {
      grid-area: q;
    }
    &:nth-child(18) {
      grid-area: r;
    }
    &:nth-child(19) {
      grid-area: s;
    }
    &:nth-child(20) {
      grid-area: t;
    }
    &:nth-child(21) {
      grid-area: u;
    }
    &:nth-child(22) {
      grid-area: v;
    }
    &:nth-child(23) {
      grid-area: w;
    }
    &:nth-child(24) {
      grid-area: x;
    }
    &:nth-child(25) {
      grid-area: y;
    }
    &:nth-child(26) {
      grid-area: z;
    }
    &:nth-child(27) {
      grid-area: aa;
    }
    &:nth-child(28) {
      grid-area: ab;
    }
    &:nth-child(29) {
      grid-area: ac;
    }
    &:nth-child(30) {
      grid-area: ad;
    }
    &:nth-child(31) {
      grid-area: ae;
    }
    &:nth-child(32) {
      grid-area: af;
    }
    &:nth-child(33) {
      grid-area: ag;
    }
    &:nth-child(34) {
      grid-area: ah;
    }
    &:nth-child(35) {
      grid-area: ai;
    }
    &:nth-child(36) {
      grid-area: aj;
    }
    &:nth-child(37) {
      grid-area: ak;
    }
    &:nth-child(38) {
      grid-area: al;
    }
    &:nth-child(39) {
      grid-area: am;
    }
    &:nth-child(40) {
      grid-area: an;
    }
    &:nth-child(41) {
      grid-area: ao;
    }
    &:nth-child(42) {
      grid-area: ap;
    }
  }
}

.lightpick__day {
  display: flex;
  height: 40px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  font-size: 13px;
  justify-content: center;
  align-items: center;
  cursor: default;
}

.lightpick__day.is-today {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Ccircle fill='rgba(220, 50, 47, .5)' cx='16' cy='16' r='16'/%3E%3C/svg%3E");
  background-size: 19% auto;
  background-position: 50% 95%;
  color: #DC322F;
}

.lightpick__day:not(.is-disabled):hover {
  background-size: contain;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Ccircle fill='%23E0E0E0' cx='16' cy='16' r='16'/%3E%3C/svg%3E");
}

.lightpick__day.is-disabled {
  opacity: 0.38;
  pointer-events: none;
}

.lightpick__day.disabled-tooltip {
  pointer-events: auto;
}

.lightpick__day.is-disabled.is-forward-selected {
  opacity: 1;
}
.lightpick__day.is-disabled.is-forward-selected:not(.is-start-date) {
  background-color: rgba(38, 139, 210, 0.1);
  background-image: none;
}

.lightpick__day.is-previous-month,
.lightpick__day.is-next-month {
  opacity: 0.38;
}

.lightpick__day.lightpick__day.is-in-range:not(.is-disabled) {
  opacity: 1;
}

.lightpick__day.is-in-range {
  border-radius: 0;
  background-color: transparentize($primary, 0.9);
  background-image: none;
}

.lightpick__day.is-in-range:hover {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Ccircle fill='rgba(38, 139, 210, 0.5)' cx='16' cy='16' r='16'/%3E%3C/svg%3E");
}

.lightpick__day.is-start-date.is-in-range,
.lightpick__day.is-end-date.is-in-range.is-flipped {
  border-top-left-radius: 50%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 50%;
  background-color: $primary;
  background-image: none;
}

.lightpick__day.is-end-date.is-in-range,
.lightpick__day.is-start-date.is-in-range.is-flipped {
  border-top-left-radius: 0;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 0;
  background-color: $primary;
  background-image: none;
}

.lightpick__day.is-start-date.is-end-date {
  background-color: transparent;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Ccircle fill='%231E4258' cx='16' cy='16' r='16'/%3E%3C/svg%3E");
}

.lightpick__day.is-start-date,
.lightpick__day.is-end-date,
.lightpick__day.is-start-date:hover,
.lightpick__day.is-end-date:hover {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Ccircle fill='%231E4258' cx='16' cy='16' r='16'/%3E%3C/svg%3E");
  background-size: auto;
  background-position: center;
  color: #FFF;
  font-weight: bold;
}

.lightpick__tooltip {
  position: absolute;
  margin-top: -4px;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #FFF;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
  white-space: nowrap;
  font-size: 11px;
  pointer-events: none;
}

.lightpick__tooltip::before {
  position: absolute;
  bottom: -5px;
  left: calc(50% - 5px);
  border-top: 5px solid rgba(0, 0, 0, 0.12);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: "";
}

.lightpick__tooltip::after {
  position: absolute;
  bottom: -4px;
  left: calc(50% - 4px);
  border-top: 4px solid #FFF;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: "";
}
.lightpick__footer {
  display: flex;
  justify-content: space-between;
}
.lightpick__reset-action,
.lightpick__apply-action {
  border-radius: 5px;
  font-size: 12px;
  border: none;
}
.lightpick__reset-action {
  color: #fff;
  background-color: #aeacad;
}
.lightpick__apply-action {
  color: #fff;
  background-color: #2495f3;
}