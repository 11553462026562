.split-banners {
  .split-banner {
    // Img/text order should alternate for each banner instance
    &:nth-child(even) {
      .split-banner__content {
        flex-direction: row-reverse;

        @media screen and (max-width: breakpoint-max(sm) ) {
          flex-direction: column;
        }
      }
    }

    .split-banner__content {
      display: flex;
      flex-direction: row;

      @media screen and (max-width: breakpoint-max(sm)) {
        flex-direction: column;
      }

      .logo {
        width: 100%;
        max-width: 185px;
        height: auto;
      }

      .img-container {
        height: auto;
        min-height: 300px;

        img {
          width: 100%;
          height: auto;
          object-fit: cover;
        }

        &__compat {
          img {
            opacity: 0;
          }
        }
      }

      p {
        a {
          font-weight: $font-weight-bold;
          text-decoration: underline;

          &:hover {
            // text-decoration: none;
          }
        }
      }
    }

    &.bg-black {
      .split-banner__content {
        a {
          color: $white;

          &:hover {
            color: $green;
          }

        }
      }
    }
  }
}

.scroll-margin {
scroll-margin-top:-17px;

        @media screen and (max-width: breakpoint-max(sm) ) {
          scroll-margin-top:-60px;
        }

        @media screen and (max-width: breakpoint-max(md) ) {
          scroll-margin-top:-60px;
        }

        @media screen and (max-width: breakpoint-max(lg) ) {
          scroll-margin-top:-60px;
        }

}