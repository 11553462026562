main.site-map {
  background-color: $blue-25;

  .site-map-title {
    margin: 4rem 0;

    @media screen and (max-width: breakpoint-max(xs)) {
      margin: 3rem map_get($spacers, 3) 2rem map_get($spacers, 3);
    }

    h1 {
      font-size: $font-size-base * 2.3125;
      font-weight: 300;

      @media screen and (max-width: breakpoint-max(xs)) {
        font-size: 3rem;
      }
    }

    .bullet {
      height: 13px;
      width: 40px;
    }
  }

  .site-map-subtitle {
    font-size: $font-size-base * 1.875;
  }

  .site-map-copy {
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    .level-0 {
      @media only screen and (max-width: breakpoint-max(sm)) {
        font-size: 125%;
      }

      > li {
        margin-bottom: map_get($spacers, 3);
      }

      .site-map-page {
        > .bullet {
          background-color: $orange;
        }
      }
    }

    .level-1 {
      padding-left: map_get($spacers, 5);

      .site-map-page {
        > .bullet {
          background-color: $primary;
        }
      }
    }

    .level-2 {
      padding-left: map_get($spacers, 5);

      .site-map-page {
        > .bullet {
          background-color: $constructiveblue;
        }
      }
    }

    .level-3 {
      padding-left: map_get($spacers, 5);

      .site-map-page {
        > .bullet {
          background-color: $balancingblue;
        }
      }
    }

    // Adjust bullet styling to prevent pixel rounding issues with this smaller version
    .bullet {
      height: 5px;
      width: 1rem;
    }
  }
}