.social-icon {

    // padding: 9px;

    &.fa-envelope {
        background-color: $white;
        border-radius: 50%;
    }

    color: $black;

    &--linkedin,
    &--facebook,
    &--instagram,
    &--twitter, 
    &--youtube {
        &:before {
            background-color: $white;
            border-radius: 50%;
        }
    }


    &.fa-envelope {
        padding: 9px;
    }

    &--linkedin {
        &:before {
            padding: 9px 12px 9px 12px;
            border: 1px solid;
        }
    }
    &--facebook {
        &:before {
            padding: 9px 14px 9px 14px;
            border: 1px solid;
        }
    }
    &--instagram {
        &:before {
            padding: 9px 11px 9px 11px;
            border: 1px solid;
        }
    }
    &--twitter {
        &:before {
            padding: 9px 10px 9px 10px;
            border: 1px solid;
        }
    }
    &--youtube {
        &:before {
            padding: 9px 8px 9px 8px;
            border: 1px solid;
        }
    }


    &.fa-envelope {
        &:hover {
            background-color: $lightgreen;
        }
    }

    &--linkedin,
    &--facebook,
    &--instagram,
    &--twitter, 
    &--youtube {
        &:hover {
            &:before {
                background-color: $lightgreen;
            }
        }
    }

}







