// Calculate collapsible bio width
// Widths must match the distance between the left most figure and the right most figure (Figures are set to justify-content: around

// >= 'SM' Breakpoint (>= 576PX VW)
$combined-figures-width-sm: calc(((2 * #{$team-figure-sm-width}) + (2 * #{$grid-gutter-width})));
$total-figures-margin-sm: calc((100% - #{$combined-figures-width-sm}));
$figures-outer-margin-sm: calc((#{$total-figures-margin-sm} / 2));
$bio-matched-width-sm: calc(100% - (#{$figures-outer-margin-sm} - #{$grid-gutter-width}));

// >= 'MD' Breakpoint (>= 768PX VW)
$bio-matched-width-md: calc(100% - #{$figures-outer-margin-sm});

// >= 'LG' Breakpoint (>= 992PX VW)
$combined-figures-width-lg: calc(((3 * 265px) + (3 * #{$grid-gutter-width})));
$total-figures-margin-lg: calc((100% - #{$combined-figures-width-lg}));
$figures-outer-margin-lg: calc((#{$total-figures-margin-lg} / 6) * 2);
$bio-matched-width-lg: calc(100% - (#{$figures-outer-margin-lg} - #{$grid-gutter-width}));


.collapsible-bio {
  position: relative;
  width: 100%;
  background-color: $white;

  @media screen and (min-width: breakpoint-min(sm)) {
    width: $bio-matched-width-sm;
  }

  @media screen and (min-width: breakpoint-min(md)) {
    width: $bio-matched-width-md;
  }

  @media screen and (min-width: breakpoint-min(lg)) {
    width: $bio-matched-width-lg;
  }

  button.close {
    position: absolute;
    top: 15px;
    right: 15px;
    opacity: 1;
  }

  .bio-headings {
    &__subheading {
      font-family: $font-family-heading;
    }
    &__mainheading {
      font-family: $font-family-heading;
    }
  }

  border-bottom: 4px solid $primary;
}