.slider__carousel {
  position: relative;
  // Default background color. May be overwritten by a color class passed into the include.
  background-color: $black;

  .slick-track {
    display: flex;

    .slick-slide {
      display: flex;
      flex-direction: column;

      & > div {
        display: flex;
        flex-grow: 1;
      }
    }
  }


  .slider-controls {
    @media screen and (max-width: breakpoint-max(md)) {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        width: 2px;
        height: 100%;
        background-color: transparentize($white, 0.5);
        transform: translateX(-50%);
      }
    }

    .slider-control {
      position: absolute;
      top: 0;
      height: 100%;
      cursor: pointer;

      .slick-arrow:not(.slick-hidden) {
        display: flex;
        justify-content: center;
        min-width: 25px;
      }

      @media screen and (max-width: breakpoint-max(md)) {
        position: relative;
        display: inline-block;

        svg {
          stroke-width: 5;
          height: 40px;
          width: auto;

          * {
            stroke-width: 5;
          }
        }

        &.slider-previous {
          left: map_get($spacers, 2);
        }

        &.slider-next {
          right: map_get($spacers, 2);
        }
      }

      @media screen and (min-width: breakpoint-min(lg)) {
        svg {
          stroke-width: 2;
          width: 40px;
          height: auto;

          * {
            stroke-width: 2;
          }
        }

        &.slider-previous {
          left: calc(-40px - #{map_get($spacers, 4)});
        }

        &.slider-next {
          right: calc(-40px - #{map_get($spacers, 4)});
        }
      }
    }
  }

  .slide-item {
    // Service title heights should all be at least 'two lines' in height to line up all heading accents. Flex the
    // heading to ensure the text always sits at the bottom of its assumed space.
    h5 {
      display: flex;
      align-items: flex-end;
      font-size: $font-size-base * 1.125;
      min-height: 42px;
    }

    .service-icon {
      svg {
        width: 130px;
        height: 130px;
      }
    }
  }

  @media screen and (max-width: breakpoint-max(md)) {
    .slick-dotted.slick-slider {
      margin-bottom: map_get($spacers, 3);
    }
  }

  .slick-dots {
    position: relative;
    list-style: none;
    display: block;
    text-align: center;
    margin-top: map_get($spacers, 4);
    margin-bottom: map_get($spacers, 1);
    padding: 0;
    width: 100%;

    li {
      position: relative;
      display: inline-block;
      width: 10px;
      height: 10px;
      padding: 0;
      cursor: pointer;

      &:not(:last-of-type) {
        margin-right: map_get($spacers, 2);
      }

      button {
        position: absolute;
        bottom: 0;
        padding: 0;
        border: 1px solid $constructiveblue;
        border-radius: 50%;
        background: transparent;
        display: block;
        width: 100%;
        height: 100%;
        outline: none;
        line-height: 0;
        font-size: 0;
        color: transparent;
        cursor: pointer;
        transition: 0.3s;
      }

      &.slick-active {
        button {
          background-color: $constructiveblue;
        }
      }
    }
  }

  .news-resource-card__heading {
    font-weight: $font-weight-medium;
  }

  .btn.btn-accent {
    font-size: 30px;
    font-weight: $font-weight-regular; 

    &:hover {
      color: $lightgreen;
    }
  }
}

.governmentSolutionsLanding .slider__carousel {
  color: $orange;
}


.governmentSolutionsLanding .slider__carousel .slick-dots li.slick-active button {
  background-color: $orange;
}

.governmentSolutionsLanding .slider__carousel .slick-dots li button {
  border: 1px solid $orange;
}

.governmentSolutionsLanding .slider__carousel .slider-controls .slider-control svg * {
  stroke: $orange;
}

.governmentSolutionsLanding h6 {
  font-family: $font-family-heading;
  font-size: 16px;
  text-transform: uppercase;
}

.lifecycle-slider img {
  max-height: 120px !important;
  max-width: 120px !important;
  margin: auto !important;
}