// This stylesheet is used to contain the small 'handful' of custom mixins used within this project.

// This mixin allows us to not only define hover colors for the button bg and text, but also for the corner accent too.
@mixin button-brand($background-color, $hover-background-color, $accent-color, $hover-accent-color, $accent-size: $btn-accent-size, $color: color-yiq($background-color), $hover-color: color-yiq($hover-background-color)) {
  color: $color;
  // background-color: $background-color;

  &.btn-accent {
    &::after {
      // border-bottom: $accent-size solid $accent-color;
    }

    &:hover:not(.no-hover) {
      &::after {
        border-bottom: $accent-size solid $hover-accent-color;
      }
    }
  }

  &:hover:not(.no-hover) {
    // color: color-yiq($hover-background-color) !important;
    // background-color: $hover-background-color;
  }
}

// Adds a full width blue block for visual separation between nav and start of page content
@mixin header-spacer ($spacer-color: $blue) {
  position: relative;
  padding-top: $spacer * 3.875;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: $spacer * 3.875;
    background-color: $black;
  }

  @media screen and (max-width: breakpoint-max(sm)) {
    padding-top: $spacer * 0.625;

    &::before {
      height: $spacer * 0.625;
    }
  }
}

// Pass in a class for the parent, then apply fill and stroke color (optional)
@mixin svg-variant($parent, $fill-color, $stroke-color: inherit) {
  #{$parent} {
    * {
      fill: $fill-color !important;
      stroke: $stroke-color !important;
    }
  }
}

// Varying sizes of box-shadows
@mixin z-depth-0 {
  box-shadow: none !important;
}

@mixin z-depth-1 {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

@mixin z-depth-1-half {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.17);
}

@mixin z-depth-2 {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.21);
}

@mixin z-depth-3 {
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

@mixin z-depth-4 {
  box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21);
}

@mixin z-depth-5 {
  box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22);
}


@mixin link-hover-anim($duration: 0.3s) {
  transition: all $duration;
}