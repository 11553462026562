* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Extend existing BS font-sizes, for mobile
@media screen and (max-width: breakpoint-max(xs)) {
  h1, .h1 { @include font-size($h1-font-size-mobile); }
  h2, .h2 { @include font-size($h2-font-size-mobile); }
  h3, .h3 { @include font-size($h3-font-size-mobile); }
  h4, .h4 { @include font-size($h4-font-size-mobile); }
  h5, .h5 { @include font-size($h5-font-size-mobile); }
  h6, .h6 { @include font-size($h6-font-size-mobile); }
}

hr {
  border-top: $hr-border-width solid transparentize($white, 0.92);
}

.font-family-sans-serif {
  font-family: $font-family-sans-serif;
}

// Alternative sans-serif font - To be used as override for default 'Nunito' body font family.
.font-family-sans-serif {
  font-family: $font-family-sans-serif;
}

.font-weight-medium {
  font-weight: 500 !important;
}

.font-weight-semi-bold {
  font-weight: 600 !important;
}

.font-weight-extra-bold {
  font-weight: 800 !important;
}

.p-small {
  font-size: $font-size-p-small !important;
}

.break-word {
  word-break: break-word;
}

.no-wrap {
  white-space: nowrap;
}

// General rule for Font Awesome social media icons generated from $social-icons map.
.social-icon {
  display: inline-block;
  font-family: "Font Awesome 5 Brands";
  font-size: $font-size-base * 1.5;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

// Generate classes for each icon in $social-icons map.
@each $icon, $value in $social-icons {
  .social-icon--#{$icon} {
    &:before {
      content: "#{$value}";
    }
  }
}

.heading-accent {
  position: relative;
  margin-bottom: map_get($spacers, 5);

  @each $color, $value in $theme-colors {
    &--#{$color} {
      &::before, &::after {
        background-color: $value;
      }
    }
  }
}