main.contact-us {
  .address-block {
    padding: 0 1rem;

    &--accent {
      display: flex;
      flex-direction: column;
      border-left: 2px solid $orange;
    }
    
    @media only screen and (min-width: breakpoint-min(sm)) {
      margin-top: map_get($spacers, 3);

      .address-title {
        min-height: 3rem;
      }
    }

    span {
      display: block;
    }

    a {
      display: block;
      color: inherit;

      &:hover {
        text-decoration: none;
      }
    }

    .address-hr {
      display: block;
      width: 150px;
      max-width: 75%;
      margin-left: 0;
      text-align: left;

      @media screen and (max-width: breakpoint-max(sm)) {
        margin-left: auto;
      }
    }

    .email-address, .website-url {
      // Prevent URL's from overflowing container
      word-break: break-word;
    }

    .website-url {
      margin-top: auto;
    }

    &.custom-address-block {
      p {
        margin-bottom: 0;
      }
    }
  }
}

main.contact-us {
  .address-block {
    padding: 0 1rem;

    .address-info, .contact-info {
      span, a {
        font-weight: $font-weight-regular;
      }
    }

    &--accent {
      display: flex;
      flex-direction: column;
      border-left: 1px solid $orange;
    }

    @media only screen and (min-width: breakpoint-min(sm)) {
      margin-top: map_get($spacers, 3);

      .address-title {
        min-height: 3rem;
      }
    }

    span {
      display: block;
    }

    a {
      display: block;
      color: inherit;
      padding: 9px;

      &:hover {
        text-decoration: none;
      }
    }

    .address-hr {
      display: block;
      width: 150px;
      max-width: 75%;
      margin-left: 0;
      text-align: left;

      @media screen and (max-width: breakpoint-max(sm)) {
        margin-left: auto;
      }
    }

    .email-address, .website-url {
      // Prevent URL's from overflowing container
      word-break: break-word;
    }

    .website-url {
      margin-top: auto;
    }

    &.custom-address-block {
      p {
        margin-bottom: 0;
      }
    }
  }
}

main.contactUsRequestAProposal {
  @include header-spacer;

  .form__success {
    margin-top: map_get($spacers, 4);
  }

  .form-success__title {
    display: flex;
    margin-bottom: map_get($spacers, 3);

    @media screen and (min-width: breakpoint-min(md)) {
      flex-flow: row-reverse;
      justify-content: center;
      align-items: center;
    }
  }


}
.contactUsLanding {
  .intro__button-wrapper, .hero__button-wrapper {
    .btn.btn-accent {
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;

      &:hover {
        font-weight: 500;
      }
    }
  }

  .office-locations-block {
    .row {
      margin-left: 0;
      margin-right: 0;
    }
  }

  #generalContactForm, .contact-info {
    .btn {
      &.btn {
        &-primary, &-red {
          &:hover {
            background-color: $lightgreen;
            color: $black !important;
          }
        }
      }
    }
  }
}