// Hover Shadows
.hoverable {
  transition: box-shadow .25s;
  box-shadow: none;
}

.hoverable:hover {
  transition: box-shadow .25s;
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.no-shadow {
  box-shadow: none !important;
}

// Z-levels as classes
.z-depth-0 {
  @include z-depth-0;
}

.z-depth-1 {
  @include z-depth-1;
}

.z-depth-1-half {
  @include z-depth-1-half;
}

.z-depth-2 {
  @include z-depth-2;
}

.z-depth-3 {
  @include z-depth-3;
}

.z-depth-4 {
  @include z-depth-4;
}

.z-depth-5 {
  @include z-depth-5;
}
