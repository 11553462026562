// Fragment link used by category anchors. Top values offset the height of the nav (+ breathing room)
#articleCategorySelectFragment {
  display: block;
  position: relative;
  top: $nav-offset-fragment-size-desktop;
  visibility: hidden;

  @media screen and (min-width: breakpoint-min(md)) {
    top: $nav-offset-fragment-size-mobile;
  }
}

.category-select {
  &__dropdown-toggle {
    display: block;
    // border: 1px solid $white;

    &[aria-expanded="true"] {
      svg {
        transform: rotate(90deg);
      }
    }

    svg {
      width: auto;
      height: 18px;
      transition: 0.2s ease-in-out;

      * {
        stroke: $orange;
        fill: $orange;
      }
    }
  }

  .btn.category-select__category {
    font-family: $font-family-sans-serif;
    font-size: $font-size-base * 1.15;
    font-weight: $font-weight-regular;

    @media screen and (min-width: breakpoint-min(md)) {
      // border: 1px solid $blue;

      &:hover {
        // border: 1px solid $orange;
      }
    }

    @media screen and (max-width: breakpoint-max(sm)) {
      display: block;
      padding-top: $spacer * 0.75;
      padding-bottom: $spacer * 0.75;
      background-color: $black;
      // border-bottom: 1px solid $lightblue;
    }

    span {
      position: relative;
      z-index: 2;
      transition: color 250ms ease-in-out;
    }

    &:after {
      position: absolute;

      content: ' ';

      width: 0;
      height: 100%;

      left: 0;
      top: 0;

      z-index: 1;

      transition: width 250ms ease-in-out,
                  background-color 250ms ease-in-out;
    }

    &:hover {
      &:after {
        width: 100%;
        background-color: $lightgreen;
      }

      span {
        color: $black;
      }
    }

    &--active {
      color: $white;

      position: relative;

      &:after {
        width: 100%;
        background-color: $red;
      }
      // background-color: $red;
      // border-width: 1px 0;

      @media screen and (min-width: breakpoint-min(md)) {
        // border: 1px solid $lightblue;
      }
    }
  }

  .dropdown-menu {
    @include z-depth-1;

    .category-select__category {
      display: block;
    }
  }
}