.article__content {
  .row {
    &--paragraphVideo {
      .embed-responsive-4by3.embed-responsive--solo-video {
        @media screen and (min-width: breakpoint-min(md)) {
          &::before {
            padding-top: 56.25%;
          }
        }
      }
      .video-thumbnail {
        // <a> doesn't require an href, let's still give it a cursor though
        cursor: pointer;
      }
    }
  }

  p {
    a {
      font-weight: $font-weight-bold;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    strong {
      font-weight: $font-weight-bold;
    }
  }

  ul {
    li {
      margin-bottom: 20px;
      font-family: $font-family-sans-serif;
      font-weight: 500;
    }
  }

  .sign-off-link {
    font-weight: $font-weight-semi-bold;
  }

  .right-slim-arrow {
    height: 15px;
    margin-top: -2px;

    * {
      stroke-width: 3px;
    }
  }
}