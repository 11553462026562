.call-to-action {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    position: relative;

    .cta-backgroud-opacity.bg-pale-blue {
        background-color: rgba($black, .5) !important;
    }

    .cta-backgroud-opacity.bg-blue-25 {
        background-color: rgba($black, .5) !important;
    }

    &__icon {
        // max-height: 175px;
        margin-right: 20px;

        @media screen and (max-width: breakpoint-max(md)) {
            // max-height: 95px;
        }


    }

    div.call-to-action--nest > .container.position-relative {
        z-index: 2;
    }

    &:after {
        position: absolute;
        content: ' ';
        background-image: url('../img/icons/line.svg');
        background-size: cover;
        background-position: -25% center;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        cursor: none;
        pointer-events: none;
        top: 0;
        left: 0;
        z-index: 1;
    }

    .cta-link {
        &:hover {
            background-color: $green;
            color: $black;
        }
    }
}
