main.governmentRestrictions {
  .tableau-dashboard {
    .tableauPlaceholder {
      position: relative;
      width: 100%;
      min-height: 300px;
      background-color: transparentize($balancingblue, 0.5);

      // This overlays the witt o'briens logo, if the Iframe doesn't load.
      &::before {
        content: "";
        position: absolute;
        max-width: 360px;
        width: calc(100% - (2 * #{map_get($spacers, 3)}));
        height: 100%;
        left: 50%;
        top: 50%;
        padding: 0 map_get($spacers, 3);
        background-image: url('../img/witt-obriens-logo-colour.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        opacity: 0.3;
        transform: translate(-50%, -50%);
        z-index: -1;
      }
    }
  }

  .wysiwyg {
    .container {
      > *:last-child {
        margin-bottom: 0;
      }
    }
  }
}