.btn {
  position: relative;
  padding: 0px;
  border-radius: 0;
  border: none;
  text-transform: uppercase;
  font-family: $font-family-sans-serif;
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.5px;

  &.btn-accent {
    // min-width: 200px;
    font-size: $font-size-base * 0.875;
    font-weight: 700;
    @include z-depth-1;

    &:hover {
      @include z-depth-1-half;
    }

    // Accent style buttons should get larger padding on anything above mobile
    @media screen and (min-width: breakpoint-min(sm)) {
      // padding: map_get($spacers, 2) map_get($spacers, 4);
    }

    &::after {
      // content: "";
      // position: absolute;
      // bottom: 0;
      // right: 0;
      // width: 0;
      // height: 0;
      // border-left: $btn-accent-size solid transparent;
      // border-top: $btn-accent-size solid transparent;
      // transition: 0.3s ease;
    }

    &.btn-lg {
      // Irregular top padding to compensate for the artificial line-height forced by Montserrat
      padding: calc(#{$spacer} * 0.675) map_get($spacers, 4) map_get($spacers, 2) map_get($spacers, 4);
      font-size: $font-size-base;
    }
  }

  @media screen and (max-width: breakpoint-max(xs)) {
    display: block;
    // padding: map_get($spacers, 2) map_get($spacers, 5);
  }

  // SCF Brand buttons as per brand guidelines. Defines the various hover states and their exceptions where necessary.
  // Where 'light' and 'dark' modifiers are used, these refer to the hover state.
  @each $color, $value in $theme-colors {
    &.btn-#{$color} {
      @if $value == $orange {
        @include button-brand($orange, $white, $white, $orange);

        &--alt {
          @include button-brand($orange, $blue, $blue, $orange);
        }
      }

      @elseif $value == $blue {
        @include button-brand($blue, $orange, $orange, $blue);
      }

      @elseif $value == $balancingblue {
        @include button-brand($balancingblue, $constructiveblue, $constructiveblue, $balancingblue);
      }
    }
  }

  &.btn-transparent {
    @include button-brand("transparent", $gray-300, $white, $primary, $color: $white);

    &:not(:hover, .active) {
      box-shadow: none !important;
    }
  }

  .btn-hover-green {
    &:hover {
      color: $green;
    }
  }

  .btn-hover-red {
    &:hover {
      color: $red;
      font-weight: 700;
    }
  }
}
