#news-carousel {
  #news-slider {
    .slick-dots {
      li {
        &.slick-active {
          button {
            background-color: $white;
          }
        }

        button {
          background-color: transparentize($white, 0.8);
        }
      }
    }
  }

  #news-slider + .slider-controls {
    @media screen and (max-width: breakpoint-max(md)) {
      // Custom spacer increment required here
      margin: map_get($spacers, 5) 0 0 0;
    }

    &:after {
      background-color: $white;
    }

    .slick-arrow {
      &:hover {
        svg * {
          stroke: $green;
        }
      }

      svg * {
        stroke: $orange;
        transition: stroke 0.1s;
      }
    }
  }

  .container--cta {
    @media screen and (min-width: breakpoint-min(lg)) {
      margin-top: map_get($spacers, 5) !important;
    }
  }
}