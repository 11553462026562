main.news, main.resources {
  .article {
    &__details {
      span {
        position: relative;
        padding-right: calc(#{map_get($spacers, 2)} + 1px);
        font-family: $font-family-sans-serif;
        font-weight: $font-weight-normal;
        font-size: $font-size-p-small;
        text-transform: uppercase;
        margin-right: map_get($spacers, 2);


        &:not(:last-of-type) {
          &::after {
            content: '';
            position: absolute;
            right: 0;
            bottom: 0;
            height: 13px;
            width: 1px;
            transform: translateY(-18%);
            background-color: map_get($theme-colors, "secondary");
          }
        }
      }
    }

    &__footer {
      hr {
        background-color: map_get($theme-colors, "orange");
      }
    }

    &__download-and-share {
      @media screen and (min-width: breakpoint-min(md)) {
        > *:not(:last-child) {
          margin-right: map_get($spacers, 5)
        }
      }

      .article__social-links {
        > a {
          text-decoration: none;

          i {
            transition: 0.2s;
          }

          &:hover {
            i {
              color: $orange;
            }
          }

          :not(:last-child) {
            margin-right: map_get($spacers, 3);
          }
        }
      }
    }
  }

  .primary-ctas {
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: $font-size-base;
      color: $black;

      &:hover {
        color: $black;
        background-color: $lightgreen;

        svg {
          * {
            stroke: $black !important;
          }

          &.primary-cta__icon {
            * {
              fill: $black !important;
            }
          }
        }
      }

      .primary-cta__icon-wrapper {
        width: 30px;
        height: 30px;
      }

      > svg.primary-cta__icon {
        width: 30px;
        height: auto;
      }

      > svg.primary-cta__arrow {
        width: 10px;
        height: 15px;

        * {
          stroke: $blue;
          stroke-width: 6;
          transition: $btn-transition;
        }
      }
    }
  }

  .news-resource-card {
    &__heading {
      font-weight: $font-weight-medium;
    }
  }

  .related-articles {
    > h2 {
      font-size: $h6-font-size;
      text-transform: uppercase;

      @media screen and (max-width: breakpoint-max(md)) {
        font-size: $h3-font-size;
        text-transform: unset;
      }
    }

    @media screen and (max-width: breakpoint-max(xs)) {
      background-color: $pale-blue-30;

      .slider__carousel {
        background-color: $pale-blue-30 !important;
      }

      .news-resource-card {
        .news-resource-card__body {
          background-color: $white !important;
        }
      }
    }
  }
}

/* .newsLanding {
  .pagination {
    .page-item {
      .page-link {
        color: $black;
      }

      &:hover {
        .page-link {
          color: $red;
        }
      }
    }
  }
} */

.resources, .news {
  
  .news-resource-card {
        
    &__heading {
        color: $black;
    }

    &__sundries {
        span {
            color: $black;
        }
    }

    &:hover {
        .news-resource-card__category {
            color: $black;
        }
    }
}
}