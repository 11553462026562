$nr-card-hover-bg-color: $green;
$nr-card-hover-color: $black;

$nr-card-hover-bg-alt-color: $green;
$nr-card-hover-alt-color: $black;
$nr-card-transition: 0.2s ease-in-out;

.news-resource-card {
  // border-bottom: 2px solid $lightblue;
  z-index: 0;
  width: 100%; /* Fixes IE11 text overflow */
  // border-top: 10px solid #fe383a;

  &.slide-card {
    // border-bottom-color: $lightgreen;
    justify-content: flex-start;

    @media screen and (max-width: breakpoint-max(sm)) {
      width: 280px!important;
    }

  }

  &__header {
    position: relative;
    height: 235px;
    background-color: #f7f7f7;

    &.intro-image {
      height: 206px;
    }

    .news-resource-card__fallback-logo {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 75%;
      height: auto;
      transform: translate(-50%, -50%);

      &--emblem {
        width: 70%;
        transition: $transition-scale;
      }
    }

    img {
      transition: $transition-scale;
    }
  }

  &__body {
    position: relative;
    transition: $nr-card-transition;
    z-index: 1;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0;
      background-color: transparentize($nr-card-hover-bg-color, 0.5);
      z-index: -1;
      transition: 0.3s ease-in-out;
    }
  }

  &__category {
    color: $orange;
    font-size: $font-size-sm;
    transition: $nr-card-transition;
  }

  &__heading {
    font-size: $font-size-base * 1.125;
    font-weight: $font-weight-semi-bold;
    transition: $nr-card-transition;
    min-height: 60px; /* Workaround for flex not working on Safari */
    color: $white;

    &.slide-card {
      font-size: $font-size-base;
      color: $black;
    }
  }

  .news-resource-card {
    &__sundries {
      span {
        position: relative;
        padding: map_get($spacers, 2);
        font-size: $font-size-sm;
        text-transform: uppercase;
        transition: $nr-card-transition;
        color: $white;
  
        &:not(:last-child) {
          &::after {
            content: '';
            position: absolute;
            top: 50%;
            right: calc(0.5 * #{map_get($spacers, -1)});
            height: $font-size-base;
            width: 1px;
            background-color: $blue;
            transform: translateY(-50%);
            transition: $nr-card-transition;
          }
        }
      }
    }

    &__body.bg-white {
      .news-resource-card {
        &__heading {
          color: $black;
        }

        &__sundries span{
          color: $black;
        }
      }
    }

    /* &:hover {
      .news-resource-card {
        &__heading {
          color: $black;
        }
        &__sundries {
          span {
            color: $black;
          }
        }
      }
    } */
  }

  &__details {
    p {
      position: relative;
      text-transform: uppercase;

      svg {
        position: absolute;
        height: 20px;
        right: -25px;
        bottom: 0;

        * {
          fill: $orange;
          transition: fill 0.15s;
        }
      }
    }
  }

  &:hover {

    .card-main-image {
      transform: scale(1.1);
      transition: $transition-scale;
    }

    .card-fallback-image {
      width: 85%;
      transition: $transition-scale;

      &.news-resource-card__fallback-logo--emblem {
        width: 70%;
      }
    }
  }

  &:hover, &:focus {
    outline: none;

    &.blue {
      border-bottom-color: $nr-card-hover-bg-alt-color;

      &.slide-card {
        // border-bottom-color: $orange;
      }
    }

    &.pale-blue-30 {
      border-bottom-color: $nr-card-hover-bg-color;

      &.slide-card {
        border-bottom-color: $orange;
      }
    }

    .news-resource-card__body {
      color: $black;

      &::before {
        height: 100%;
        background-color: $nr-card-hover-bg-color;
      }

      .news-resource-card__category {
        // color: $white;
      }

      .news-resource-card__sundries {
        span {
          &:not(:last-child) {
            &::after {
              background-color: $nr-card-hover-color;
            }
          }
        }
      }

      &.blue {
        color: $nr-card-hover-alt-color !important;
        

        &::before {
          height: 100%;
          background-color: $nr-card-hover-bg-alt-color;
        }
  
        .news-resource-card__category,
        .news-resource-card__details p {
          color: $nr-card-hover-alt-color;
          transition: $nr-card-transition;
        }
  
        .news-resource-card__sundries {
          span {
            &:not(:last-child) {
              &::after {
                background-color: $nr-card-hover-alt-color;
              }
            }
          }
        }
      }

      &.pale-blue-30 {
        color: $nr-card-hover-color !important;

        &::before {
          height: 100%;
          background-color: $nr-card-hover-bg-color;
        }
  
        .news-resource-card__category,
        .news-resource-card__details p {
          color: $nr-card-hover-color;
          transition: $nr-card-transition;
        }
  
        .news-resource-card__sundries {
          span {
            &:not(:last-child) {
              &::after {
                background-color: $nr-card-hover-color;
              }
            }
          }
        }
      }
    }

    .news-resource-card {
      &__heading {
        color: $black;
      }
      &__sundries {
        span {
          color: $black;
        }
      }
    }
    
  }
}