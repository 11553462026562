#solutions-carousel {
  #solutions-slider + .slider-controls {
    .slick-arrow {
      svg * {
        stroke: $orange
      }
    }
  }
  .slider__carousel {
    .slider-controls:after {

    }
  }

  .solution-slide {
    border: 1px solid rgba($blue-30,.5);
    width: 282px;

    @media screen and (max-width: breakpoint-max(lg)) {
      width: auto;
    }

    @media screen and (max-width: breakpoint-max(md)) {
      width: 100%;
    }
  }

  .solution-icon-container {
    min-height: 75px;
    .solution-icon {
      img {
        width: 75px;
      }
    }
  }
}