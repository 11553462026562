main.marketsLanding {
  .generic-card {
    .card-header {
      @media screen and (max-width: breakpoint-max(sm)) {
        height: 155px;
      }
    }

    .card-body {
      p {
        @media screen and (max-width: breakpoint-max(sm)) {
          display: none !important;
        }
      }
    }
  }
}

main.markets {
  #solutions-carousel {
    background-color: $white;
    h3 {
      font-size: $font-size-base * 2;

      @media screen and (max-width: breakpoint-max(md)) {
        font-size: $font-size-base * 1.75;
      }
    }

    .slick-dots {
      margin-top: map_get($spacers, 2);
    }

    .slider-controls {
      margin-top: map_get($spacers, 4);
    }

    #solutions-slider + .slider-controls{
      &:after {
        background-color: $white;
      }

      .slick-arrow{
        &:hover {
          svg * {
            stroke: $white;
          }
        }

        svg * {
          stroke: $orange;
          transition: stroke 0.1s;
        }
      }
    }
  }
}