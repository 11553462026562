// Hero content is overlaid onto background image on tablet/desktop, but sits below the hero image on mobile as to ensure
// it doesn't obscure/cover the background image.

.hero {
  position: relative;
  width: 100%;
  // Required for IE11 fix
  overflow: hidden;

  &--simple {
    height: 500px;

    @media screen and (max-width: breakpoint-max(xs)) {
      height: 240px;
    }
  }

  &--wide-contained {
    height: 400px;

    @media screen and (max-width: breakpoint-max(sm)) {
      height: 240px;
    }
  }

  .hero__bg-img-wrapper {
    position: relative;
    height: 400px;

    @media screen and (max-width: breakpoint-max(sm)) {
      height: 200px;
    }

    // This overlays the witt o'briens logo on the fallback gradient, if an image doesn't load.
    &::before {
      content: "";
      position: absolute;
      max-width: 360px;
      width: calc(100% - (2 * #{map_get($spacers, 3)}));
      height: 100%;
      left: 50%;
      top: 50%;
      padding: 0 map_get($spacers, 3);
      background-image: url('../img/witt-obriens-logo-colour.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      opacity: 0.3;
      transform: translate(-50%, -50%);
      z-index: -1;
    }

    @media screen and (max-width: breakpoint-max(sm)) {
    }

    // This acts as a fallback gradient in the event an image doesn't load.
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -2;
      background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(209,210,209,1) 100%);
    }
  }

  .container--hero-content {
    @media screen and (min-width: breakpoint-min(md)) {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    .hero__content-wrapper {
      height: 100%;
      position: relative;

        &:before {
          content: ' ';
          position: absolute;
          width: 100%;
          height: 100%;
          background: rgba($black, 0.3);
          top: 0;
          left: 0;
      }
      
      .hero__content {
        width: 50%;
        position: relative;

        @media screen and (max-width: breakpoint-max(md)) {
          width: 50%;
        }

        // Mobile devices display full width.
        @media screen and (max-width: breakpoint-max(sm)) {
          width: 100%;
          background-color: $black;
        }

        // Angled/triangle accent appended onto hero content. Displays on tablet devices upwards
        &::before {
          /* content: '';
          position: absolute;
          height: 100%;
          width: calc(100% + 300px);
          top: 0;
          left: -150px;
          // Unique hex code required, approved by design team as a substitute for multiply effect
          background-color: transparentize(#000000, 0.1);
          transform: skew(-30deg);
          z-index: -1;

          @media screen and (max-width: breakpoint-max(md)) {
            width: calc(100% + 240px);
          }

          @media screen and (max-width: breakpoint-max(sm)) {
            display: none;
          } */
        }

        h1 {
          font-size: $font-size-base * 2.25;

          @media screen and (max-width: breakpoint-max(sm)) {
            font-size: $font-size-base * 1.75;
          }
        }

        p {
          font-size: $font-size-base * 1.25;
          line-height: 24px;

          @media screen and (max-width: breakpoint-max(sm)) {
            font-size: $font-size-base * 0.9375;
          }
        }
      }
    }
  }

  .hero__button-wrapper {
    a.btn.btn-accent {
      transition: all 350ms ease-in-out;
      font-weight: 500;

      &:hover {
        font-weight: bold;
      }
    }
  }
}
